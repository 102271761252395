import React, { Component } from 'react';
import debounce from 'debounce';
import classNames from 'classnames';
import GamesRepository from '@/data-access/games/games.repository';
import SearchIcon from '@/svg/icons/search.svg?component';
import List from './List';
import Tag from './Tag';
import styles from './GamesAutocomplete.module.scss';

export interface GameItem {
    id: string | number;
    name: string;
}

interface State {
    value: string;
    items: Array<GameItem>;
    selectedItems: Array<GameItem>;
    isLoading: boolean;
}

interface Props {
    limit?: number;
    listSize?: number;
    onChange: Function;
    initialSelectedItems: Array<GameItem>;
}

export default class GamesAutocomplete extends Component<Props, State> {
    static defaultProps = {
        limit: 3,
        listSize: 10
    };

    input: React.RefObject<any>;

    constructor(props: Props) {
        super(props);

        this.state = {
            value: '',
            items: [],
            selectedItems: this.props.initialSelectedItems,
            isLoading: false
        };
        this.input = React.createRef();
    }

    onNameChange = () => {
        const { onChange } = this.props;

        onChange(this.state.selectedItems.map(({ name }) => name));
    };

    handleGameDelete = (id: string) => {
        const { selectedItems, items } = this.state;
        const { limit } = this.props;
        const newSelectedValues = selectedItems.filter((item: GameItem) => item.id !== id);

        this.setState(
            {
                items:
                    items.length === limit
                        ? []
                        : items.filter(
                              (item: any) =>
                                  newSelectedValues.findIndex(val => val.id === item.id) === -1
                          ),
                selectedItems: newSelectedValues
            },
            () => {
                this.onNameChange();
                this.focus();
            }
        );
    };

    handleGameAdd = (item: GameItem) => {
        const { selectedItems, items } = this.state;
        const { limit } = this.props;
        const newSelectedValues = [...selectedItems, item];

        this.setState(
            {
                items:
                    newSelectedValues.length === limit
                        ? []
                        : items.filter(
                              (item: any) =>
                                  newSelectedValues.findIndex(val => val.id === item.id) === -1
                          ),
                value: '',
                selectedItems: newSelectedValues
            },
            () => {
                this.onNameChange();
                this.focus();
            }
        );
    };

    handleGameNameChange = debounce(async () => {
        const { selectedItems, value } = this.state;
        const { listSize, limit } = this.props;

        if (selectedItems.length === limit) {
            this.setState({
                isLoading: false
            });
            return;
        }

        if (value.length < 2) {
            this.setState({
                items: [],
                isLoading: false
            });
            return;
        }

        if (selectedItems.length >= limit) {
            this.setState({
                isLoading: false
            });
            return;
        }

        try {
            const items = await new GamesRepository().getGames(value);
            const mappedItems = items
                .filter((item: any) => selectedItems.findIndex(val => val.id === item.id) === -1)
                .slice(0, listSize)
                .map(item => ({
                    id: item.id,
                    name: item.value
                }));

            this.setState({
                items: mappedItems,
                isLoading: false
            });
        } catch (error) {
            console.error(error);
        }
    }, 500);

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: event.target.value, isLoading: true }, this.handleGameNameChange);
    };

    focus = () => {
        if (this.input.current) {
            this.input.current.focus();
        }
    };

    render() {
        const { value, items, selectedItems, isLoading } = this.state;
        const { limit } = this.props;
        const inputClass = classNames(styles.search, {
            [styles['search--item']]: selectedItems.length > 0
        });
        const customStyle = selectedItems.length > 0 ? { width: value.length * 16 } : {};

        return (
            <div className={styles.wrapper} onClick={this.focus}>
                <span className={styles.mob}>
                    {selectedItems.map(item => (
                        <Tag key={item.id} item={item} onDelete={this.handleGameDelete} />
                    ))}
                </span>
                <div className={styles.input}>
                    <div className={styles['tag-wrapper']}>
                        {selectedItems.length > 0 && (
                            <span className={styles.desk}>
                                {selectedItems.map(item => (
                                    <Tag
                                        key={item.id}
                                        item={item}
                                        onDelete={this.handleGameDelete}
                                    />
                                ))}
                            </span>
                        )}

                        {selectedItems.length < limit && (
                            <input
                                ref={this.input}
                                style={customStyle}
                                className={inputClass}
                                placeholder="Search games..."
                                onChange={this.handleChange}
                                value={value}
                            />
                        )}
                    </div>
                    <div className={styles['search-icon']}>
                        <SearchIcon />
                    </div>
                </div>

                {items.length > 0 && selectedItems.length < limit && (
                    <List items={items} onItemClick={this.handleGameAdd} />
                )}

                {items.length === 0 && value.length > 1 && !isLoading && (
                    <div className={styles['list-wrapper']}>
                        <div className={styles.stub} />
                        <div className={styles.list}>
                            <div
                                className={classNames(
                                    styles['list-item'],
                                    styles['list-item--disabled']
                                )}
                            >
                                <div className={styles['list-item--title']}>No results found</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
