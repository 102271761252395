import React, { useState } from 'react';
import styles from './TraitsSection.module.scss';
import PlusIcon from '@/svg/icons/plus.svg?component';
import MinusIcon from '@/svg/icons/minus.svg?component';
import classNames from 'classnames';

interface Props {
    trait: {
        title: string;
        subtitle: string;
        expandedData: {
            text: string;
            listTitle: string;
            list: string[];
        };
    };
}

const Trait = ({ trait }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div
            onClick={() => {
                if (!isExpanded) {
                    setIsExpanded(true);
                }
            }}
            className={classNames(!isExpanded ? styles.wrapper : styles['wrapper--expanded'])}
        >
            <div
                onClick={() => {
                    setIsExpanded(false);
                }}
                className={styles.head}
            >
                <h2 className={styles.title}>{trait.title}</h2>
                <div>
                    {!isExpanded ? (
                        <PlusIcon className={styles['btn-icon']} />
                    ) : (
                        <MinusIcon className={styles['btn-icon']} />
                    )}
                </div>
            </div>
            <div className={styles.content}>
                {!isExpanded ? (
                    <div className={styles.subtitle}>{trait.subtitle}</div>
                ) : (
                    <div className={styles.content_expanded}>
                        <div className={styles.content__text}>{trait.expandedData.text}</div>
                        <div className={styles['content__list-title']}>
                            {trait.expandedData.listTitle}
                        </div>
                        <ul className={styles.content__list}>
                            {trait.expandedData.list.map(item => (
                                <li key={item} className={styles['content__list-item']}>
                                    {item}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Trait;
