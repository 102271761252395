/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect, useState } from 'react';
import { Question, Session, Answer } from '@/store/survey/types';
import styles from './SurveySection.module.scss';
import ProgressBar from '@/components/ProgressBar';
import QuestionModule from './Question';
import { getAnswerType } from '@/store/survey/mappers';
import surveyRepository from '@/data-access/survey/survey.repository';
import ArrowIcon from '@/svg/arrow.svg?component';
import classNames from 'classnames';
import ButtonsGroup from '@/components/ButtonsGroup/ButtonsGroup';
import { useNavigate } from 'react-router-dom';

const hasDuplicates = (array: any[]) => new Set(array).size !== array.length;

const isValidNumber = (value: string) => {
    if (!value || !value.length) return false;
    if (!Number.isFinite(Number(value))) return false;
    return /^\d+$/.test(value) && parseInt(value, 10) > 0 && parseInt(value, 10) < 200;
};

const isUnderAge = (age: number) => age <= 16;

export interface Props extends Component {
    createSession: Function;
    setSession: Function;
    fetchQuestions: Function;
    session: Session | null;
    questions: Array<Question> | null;
    answers: Array<Answer> | null;
    currentQuestion: number | null;
    setCurrentQuestion: Function;
    setAnswer: Function;
    setAnswers: Function;
    setCompletedTest: Function;
    isCompleted: boolean;
    reset: Function;
}

const SurveySection = ({
    createSession,
    setSession,
    fetchQuestions,
    session,
    questions,
    answers,
    currentQuestion,
    setCurrentQuestion,
    setAnswer,
    setAnswers,
    setCompletedTest,
    isCompleted,
    reset
}: Props) => {
    const [withNext, setWithNext] = useState(false);
    const navigate = useNavigate();

    if (sessionStorage.getItem('startSession') === null) {
        navigate('/');
    }

    useEffect(() => {
        const sessionTime = sessionStorage.getItem('startSession');
        const session = sessionStorage.getItem('session');
        const answers = sessionStorage.getItem('answers');
        if (sessionTime && session && answers) {
            if ((parseInt(sessionTime, 10) || 0) >= JSON.parse(session).expired_at || 0) {
                setCompletedTest(false);
                createSession();
            } else {
                setSession(JSON.parse(session));
                setAnswers(JSON.parse(answers));
            }
        } else {
            setCompletedTest(false);
            createSession();
        }
    }, [createSession, setSession, setAnswers]);

    useEffect(() => {
        if (session !== null) {
            fetchQuestions(sessionStorage.getItem('answers') !== null);
        }
    }, [session, fetchQuestions, setCurrentQuestion]);

    useEffect(() => {
        if (questions !== null) {
            if (sessionStorage.getItem('currentQuestion') !== null) {
                setCurrentQuestion(parseInt(sessionStorage.getItem('currentQuestion') || '0', 10));
            } else {
                setCurrentQuestion(0);
            }
        }
    }, [questions, setCurrentQuestion]);

    useEffect(() => {
        if (currentQuestion !== null) {
            sessionStorage.setItem('currentQuestion', currentQuestion.toString());
        }
    }, [currentQuestion]);

    useEffect(() => {
        if (withNext) {
            handleNextClick();
        }

        if (answers) {
            sessionStorage.setItem('answers', JSON.stringify(answers));
        }
    }, [answers, withNext]);

    useEffect(() => {
        if (isCompleted) {
            navigate('/survey-complete');
        }
    }, [isCompleted, navigate]);

    if (!questions || !questions.length || !answers || !answers.length) {
        return <div className={styles.survey} />;
    }

    const handleNextClick = async () => {
        if (currentQuestion === null) {
            return;
        }

        if (questions[currentQuestion].type === 'single_text_input') {
            const answer = getAnswer().trim();
            const isAnswerUnderAge = isUnderAge(answer);

            if (isAnswerUnderAge) {
                reset();
                sessionStorage.clear();
                navigate('/under-age');
                return;
            }
        }

        try {
            await sendAnswer(currentQuestion);
            if (currentQuestion < questions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
            } else {
                sessionStorage.removeItem('startSession');
                sessionStorage.removeItem('currentQuestion');
                sessionStorage.removeItem('answers');
                sessionStorage.setItem('isCompleted', 'true');
                setCompletedTest(true);
            }
        } catch (e) {
            throw Error(`There is an error while sending an answer: ${e}`);
        }
    };

    const sendAnswer = async (questionIndex: number) => {
        if (!session || !answers) {
            return;
        }
        await surveyRepository.sendAnswer(session.id, answers[questionIndex]);
    };

    const handlePrevClick = () => {
        if (currentQuestion === null) {
            return;
        }

        setCurrentQuestion(currentQuestion - 1);
    };

    const handleChange = (val: any, onNext?: boolean) => {
        setAnswer(currentQuestion, val);
        setWithNext(!!onNext);
    };

    const getAnswer = () => {
        if (currentQuestion === null) {
            return null;
        }

        const answer = answers[currentQuestion];
        return answer[getAnswerType(questions[currentQuestion].type)];
    };

    const isEmptyAnswer = () => {
        const answer = getAnswer();
        if (currentQuestion === null) {
            return true;
        }
        switch (questions[currentQuestion].type) {
            case 'likert':
            case 'dropdown_single':
                return answer === null;
            case 'dropdown_multiple':
                return answer.length < questions[currentQuestion].options_min;
            case 'single_text_input':
                return !isValidNumber(answer.trim());
            case 'games_autocomplete':
                return (
                    answer.filter((ans: any) => !!ans.value).length <
                    questions[currentQuestion].options_min
                );
            case 'multiple_text_inputs': {
                const inputs = answer.map((ans: any) => ans.value.trim());
                const hasDuplicateInputs = hasDuplicates(inputs);

                if (hasDuplicateInputs) return true;

                const nonEmptyInputCount = inputs.filter(
                    (input: string) => input.length > 0
                ).length;

                return nonEmptyInputCount < questions[currentQuestion].options_min;
            }
            default:
                return false;
        }
    };

    if (currentQuestion === null) {
        return null;
    }

    return (
        <div className={styles.survey}>
            {questions && (
                <div className={classNames(styles.container, 'container')}>
                    <ProgressBar
                        currentQuestion={currentQuestion + 1}
                        totalQuestions={questions.length}
                    />
                    <QuestionModule
                        question={questions[currentQuestion]}
                        onPrev={currentQuestion > 0 ? handlePrevClick : undefined}
                        onNext={handleNextClick}
                        isEmptyAnswer={isEmptyAnswer()}
                        answer={getAnswer()}
                        onChange={handleChange}
                    />
                    <ButtonsGroup
                        className={classNames('mobile-shown--flex', styles['buttons-group'])}
                        buttons={[
                            {
                                disabled: !(currentQuestion > 0),
                                children: (
                                    <ArrowIcon
                                        className={classNames(
                                            styles['prev-arrow'],
                                            styles['prev-arrow--mobile'],
                                            {
                                                [styles['prev-arrow--disabled']]: !(
                                                    currentQuestion > 0
                                                )
                                            }
                                        )}
                                    />
                                ),
                                className: styles['buttons-group-button'],
                                onClick: handlePrevClick,
                                color: 'light-blue'
                            },
                            {
                                disabled: isEmptyAnswer(),
                                children: (
                                    <ArrowIcon
                                        className={classNames(
                                            styles['next-arrow'],
                                            styles['next-arrow--mobile'],
                                            {
                                                [styles['next-arrow--disabled']]: isEmptyAnswer()
                                            }
                                        )}
                                    />
                                ),
                                className: styles['buttons-group-button'],
                                onClick: handleNextClick,
                                color: 'light-blue'
                            }
                        ]}
                    />
                </div>
            )}
        </div>
    );
};

export default SurveySection;
