import { RecommendedGamesState } from './types';

const initialState: RecommendedGamesState = {
    games: [],
    loading: false,
    error: false,
    errorMsg: ''
};

export default initialState;
