import classNames from 'classnames';
import Button, { ButtonProps } from '@/components/Button/Button';
import styles from './ButtonsGroup.module.scss';

interface Props {
    buttons: Array<ButtonProps>;
    className?: string;
}

const ButtonsGroup = ({ buttons, className }: Props) => (
    <div className={classNames([styles['buttons-group'], className])}>
        {buttons.map(props => (
            <Button
                key={props.text}
                {...props}
                className={classNames([props.className, styles.button])}
            />
        ))}
    </div>
);

export default ButtonsGroup;
