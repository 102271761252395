import { useEffect, useState } from 'react';
import Header from '@/main/sections/Header';
import { SurveyFinished, SurveyResults } from '@/main/sections/WhiteScreen';
import '@/main/styles/common.scss';
import styles from './Survey.module.scss';
import { useNavigate } from 'react-router-dom';

const SurveyComplete = () => {
    const [step, setStep] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (
            sessionStorage.getItem('isCompleted') !== 'true' ||
            sessionStorage.getItem('session') === null
        ) {
            navigate('/');
        }
    }, []);

    const handleResultsClick = () => {
        setStep(1);
    };

    const handleOpenReport = async () => {
        if (sessionStorage.getItem('session') === null) {
            return navigate('/');
        }

        try {
            // @ts-ignore: Unreachable code error
            const sessionId = JSON.parse(sessionStorage.getItem('session')).id;
            window.open(`${window.location.origin}/user-report/${sessionId}`);
            sessionStorage.removeItem('isCompleted');
        } catch (e) {
            alert('Can not get report link');
        }

        return null;
    };
    return (
        <div className={styles.wrapper}>
            <Header clear small />
            <div className={styles.content}>
                {step === 0 ? (
                    <SurveyFinished onClick={handleResultsClick} />
                ) : (
                    <SurveyResults onClick={handleOpenReport} />
                )}{' '}
            </div>
        </div>
    );
};

export default SurveyComplete;
