import React from 'react';
import styles from './UniqueIdentifier.module.scss';
import UniquerIdentifierBlock from '@/svg/unique-identifier-block.svg?component';
import UniquerIdentifierBlockMobile from '@/svg/unique-identifier-block-mobile.svg?component';
import useIsMobile from '@/hooks/useIsMobile';

interface Props {
    playerId: string;
}

const UniqueIdentifier = ({ playerId }: Props) => {
    const isMobile = useIsMobile();

    return (
        <>
            <div className={styles.title}>As Gandalf once said, keep it secret. Keep it safe. </div>
            <div className={styles.text}>
                We highly value your privacy and because we will never be able to identify you in
                real life, this code is the only way you - and only you* - can access your Wellness
                Assessment results in the future.
            </div>

            <div className={styles['identifier-block']}>
                <span className={styles['identifier-block__text']}>{playerId}</span>
                {isMobile ? (
                    <UniquerIdentifierBlockMobile className={styles['identifier-block__icon']} />
                ) : (
                    <UniquerIdentifierBlock className={styles['identifier-block__icon']} />
                )}
            </div>
        </>
    );
};

export default UniqueIdentifier;
