import React, { ReactElement } from 'react';
import Header from '@/main/sections/Header';
import Slider from '@/main/sections/Slider';
import TraitsSection from '@/main/sections/TraitsSection';
import Footer from '@/main/sections/Footer';
import InfoSection from '@/main/sections/InfoSection';

import '@/main/styles/common.scss';
import TakeReportSection from '../sections/TakeReportSection';

const HomePage = (): ReactElement => (
    <div className="wrapper">
        <Header />
        <InfoSection />
        <TraitsSection />
        <TakeReportSection />
        <Slider />
        <Footer />
    </div>
);

export default HomePage;
