import { createRoot } from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import './index.css';
import Routes from '@/main/Routes';
import store from './store';

const root = createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
    <StoreProvider store={store}>
        <Routes />
    </StoreProvider>
);
