/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '@/store';
import { TraitsGroup, TraitIds } from './types';
import { mapToStateTraitsGroup } from './mappers';
import assessmentRepository from '@/data-access/assessment/assessment.repository';
import initialState from './initialState';

const traitsSlice = createSlice({
    name: 'traits',
    initialState,
    reducers: {
        setTraits(state, action: PayloadAction<Array<TraitsGroup>>) {
            state.groups = action.payload;
            state.selectedCount = 0;
        },

        toggleTrait(state, action: PayloadAction<TraitIds>) {
            const { groupId, traitId } = action.payload;

            // search for the clicked trait
            const clickedTrait = state.groups
                .find(group => group.id === groupId)
                ?.traits.find(trait => trait.id === traitId);

            // check if trait was found
            if (clickedTrait) {
                // if trait has been selected already toggle its value
                // and reduce selectedCount
                if (clickedTrait.selected) {
                    clickedTrait.selected = false;
                    state.selectedCount -= 1;

                    // otherwise check if its allowed to select one more trait
                } else if (state.selectedCount < 3) {
                    clickedTrait.selected = true;
                    state.selectedCount += 1;
                }
            }
        }
    }
});

export const { toggleTrait } = traitsSlice.actions;

export const fetchTraits = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await assessmentRepository.getTraits();
        const groups = response.map(mapToStateTraitsGroup);

        dispatch(traitsSlice.actions.setTraits(groups));
    } catch (error) {
        console.error(error);
    }
};

export default traitsSlice.reducer;
