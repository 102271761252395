import { Insight } from '@/data-access/survey/types';
import classNames from 'classnames';
import Card from '../../Card';
import styles from './Adaptability.module.scss';

interface Props {
    data: Insight;
}

const Adaptability = ({ data }: Props) => (
    <>
        <Card title={data.label} type={data.valueGroup} text={data.description} />
        <div className={styles.stages}>
            <div
                className={classNames(styles.stages__item, styles.stages__item_low, {
                    [styles.stages__item_active]: data.valueGroup === 'high'
                })}
            >
                <span className={styles['stages__item-unit']}>
                    Living in the present & focused on the future
                </span>
                <span className={styles['stages__item-unit']}>
                    Making talents available to those in need
                </span>
                <span className={styles['stages__item-unit']}>Thinking of and helping others</span>
                <span className={styles['stages__item-unit']}>Empathetic with self & others</span>
                <span
                    className={classNames(
                        styles['stages__item-unit'],
                        styles['stages__item-unit_main']
                    )}
                >
                    Growing
                </span>
                <span className={styles['stages__item-unit']}>Thanks & appreciates others</span>
                <span className={styles['stages__item-unit']}>
                    Keeps a happy emotional state & speads hope
                </span>
                <span className={styles['stages__item-unit']}>
                    Seeks new ways to adapt to change
                </span>
                <span className={styles['stages__item-unit']}>
                    Practices quietude, patience, relationships, & creativity
                </span>
            </div>
            <div
                className={classNames(styles.stages__item, styles.stages__item_medium, {
                    [styles.stages__item_active]: data.valueGroup === 'medium'
                })}
            >
                <span className={styles['stages__item-unit']}>
                    Giving up what can&apos;t control
                </span>
                <span className={styles['stages__item-unit']}>Stops compulsive consumption</span>
                <span className={styles['stages__item-unit']}>Identifies emotions</span>
                <span
                    className={classNames(
                        styles['stages__item-unit'],
                        styles['stages__item-unit_main']
                    )}
                >
                    Learning
                </span>
                <span className={styles['stages__item-unit']}>Aware of situation</span>
                <span className={styles['stages__item-unit']}>Evaluates info before sharing</span>
                <span className={styles['stages__item-unit']}>
                    Recognizes everyone is doing their best
                </span>
            </div>
            <div
                className={classNames(styles.stages__item, styles.stages__item_high, {
                    [styles.stages__item_active]: data.valueGroup === 'low'
                })}
            >
                <span className={styles['stages__item-unit']}>Spreading fear & anger</span>
                <span className={styles['stages__item-unit']}>Stocking up</span>
                <span className={styles['stages__item-unit']}>Coping</span>
                <span className={styles['stages__item-unit']}>Complaining</span>
                <span
                    className={classNames(
                        styles['stages__item-unit'],
                        styles['stages__item-unit_main']
                    )}
                >
                    Adjusting
                </span>
                <span className={styles['stages__item-unit']}>Gets mad easily</span>
                <span className={styles['stages__item-unit']}>Compulsive consumption</span>
                <span className={styles['stages__item-unit']}>Forwards all messages</span>
            </div>
            <div
                className={classNames(
                    styles.stages__item,
                    styles.stages__item_stage,
                    styles[`stages__item_stage_${data.valueGroup}`]
                )}
            >
                Your Stage of Adaptability
            </div>
        </div>
    </>
);

export default Adaptability;
