import { ReportData } from '@/data-access/survey/types';
import Cards from './Cards';
import {
    sections,
    sectionsSubTitles,
    inGameSurveySections,
    inGameSurveySectionsSubTitles
} from './constants';
import About from './sections/About';
import Adaptability from './sections/Adaptability';
import Citations from './sections/Citations';
import Emotional from './sections/Emotional';
import MotivationProfile from './sections/MotivationProfile';
import UniqueIdentifier from './sections/UniqueIdentifier';

const sectionComponents = [
    Cards,
    Cards,
    Cards,
    Adaptability,
    Cards,
    Cards,
    Cards,
    Cards,
    Cards,
    Cards,
    Emotional,
    MotivationProfile,
    UniqueIdentifier,
    About,
    Citations
];

const inGameSurveySectionComponents = [
    MotivationProfile,
    Emotional,
    Cards,
    UniqueIdentifier,
    About,
    Citations
];

const sectionsProps = ({ insights, playerId }: ReportData) => {
    const motivatioProfileData = [
        insights.nPurposeRc,
        insights.nMastery,
        insights.nAutonomy,
        insights.m1CompensatoryEffort,
        insights.m2RcCompetitiveness,
        insights.m3ConfInSuccess,
        insights.m4Dominance,
        insights.m5EagernessLearn,
        insights.m6RcEngagement,
        insights.m7RcFearlessness,
        insights.m8RcFlexibility,
        insights.m9Flow,
        insights.m10RcGoalSetting,
        insights.m11RcIndependence,
        insights.m12RcInternality,
        insights.m13Persistence,
        insights.m14RcDifficultTasks,
        insights.m15PrideProductivity,
        insights.m16SelfControl,
        insights.m17StatusOrientation
    ];

    return [
        {
            data: [
                insights.paOverallWellbeing,
                insights.paPhysicalWellbeing,
                insights.nSleepRc,
                insights.wbPhysicalEatHealthy
            ]
        },
        {
            data: [
                insights.nPsychWellBeing,
                insights.selfCareRelaxation,
                insights.anxietyRc,
                insights.depressionRc
            ]
        },
        {
            data: [insights.nResilience]
        },
        { data: insights.adaptabilityTotal },
        {
            data: [insights.wbPsychRcCompulsiveConsumption, insights.wbPsychRcCoping]
        },
        {
            data: [
                insights.mindfulnessMeditation,
                insights.consciousnessSelfAwareness,
                insights.consciousnessSelfReflectionTotal,
                insights.consciousnessRcSelfRumination,
                insights.consciousnessRcConnectToNature,
                insights.consciousnessRcGratitude,
                insights.flowRcDisposition,
                insights.flowState,
                insights.neoAltruism,
                insights.neoFeelingEmpathy
            ]
        },
        { data: [insights.resilienceAgilityTotal] },
        { data: [insights.resilienceCharacterTotal] },
        {
            data: [
                insights.paSocialWellbeing,
                insights.socialContacts,
                insights.isolationRc,
                insights.socialSupport
            ]
        },
        { data: [insights.resilienceOptimismTotal] },
        {
            negative: insights.panasGeneralnegativeemotion,
            positive: insights.panasGeneralpositiveemotion
        },
        {
            highest: motivatioProfileData.sort((a, b) => b.valueRaw - a.valueRaw).slice(0, 3),
            lowest: motivatioProfileData.sort((a, b) => a.valueRaw - b.valueRaw).slice(0, 3)
        },
        { playerId },
        {},
        {}
    ];
};

const inGameSurveySectionsProps = ({ insights, playerId }: ReportData) => {
    const motivatioProfileData = [
        insights.nPurposeRc,
        insights.nMastery,
        insights.nAutonomy,
        insights.m1CompensatoryEffort,
        insights.m2RcCompetitiveness,
        insights.m3ConfInSuccess,
        insights.m4Dominance,
        insights.m5EagernessLearn,
        insights.m6RcEngagement,
        insights.m7RcFearlessness,
        insights.m8RcFlexibility,
        insights.m9Flow,
        insights.m10RcGoalSetting,
        insights.m11RcIndependence,
        insights.m12RcInternality,
        insights.m13Persistence,
        insights.m14RcDifficultTasks,
        insights.m15PrideProductivity,
        insights.m16SelfControl,
        insights.m17StatusOrientation
    ];

    return [
        {
            highest: motivatioProfileData.sort((a, b) => b.valueRaw - a.valueRaw).slice(0, 3),
            lowest: motivatioProfileData.sort((a, b) => a.valueRaw - b.valueRaw).slice(0, 3)
        },
        {
            negative: insights.panasGeneralnegativeemotion,
            positive: insights.panasGeneralpositiveemotion
        },
        {
            data: [
                insights.paPhysicalWellbeing,
                insights.nSleepRc,
                insights.nPsychWellBeing,
                insights.anxietyRc,
                insights.flowState,
                insights.nResilience
            ]
        },
        { playerId },
        {},
        {}
    ];
};

export const configSections = (data: ReportData): any[] =>
    sections.map((sectionName, index) => ({
        id: index + 1,
        title: sectionName,
        subTitle: sectionsSubTitles[index],
        Component: sectionComponents[index],
        props: sectionsProps(data)[index]
    }));

export const inGameSurveyConfigSections = (data: ReportData): any[] =>
    inGameSurveySections.map((sectionName, index) => ({
        id: index + 1,
        title: sectionName,
        subTitle: inGameSurveySectionsSubTitles[index],
        Component: inGameSurveySectionComponents[index],
        props: inGameSurveySectionsProps(data)[index]
    }));
