import PenguinCrewIcon from '@/svg/penguin-crew.svg?component';
import styles from './InfoSection.module.scss';

const InfoSection = () => (
    <div className={styles.section}>
        <div className="container">
            <div className={styles.content}>
                <div className={styles.content__image}>
                    <PenguinCrewIcon />
                </div>
                <div className={styles.content__text}>
                    <h2>Pursuing a future of happiness & well-being</h2>
                    <p>
                        Whether you’re a single parent, stressed at work, or facing times of crisis
                        such as natural disasters, war, or a pandemic, Solsten is here to help.
                        We’re offering an assessment that is based on empirically validated skills
                        that build cognitive and emotional well-being. You’ll receive your free
                        personalized well-being report, bringing clarity to your unique needs with
                        tailor-made recommendations on steps you can take.
                    </p>
                </div>
            </div>
        </div>
        <div className={styles.mountain} />
    </div>
);

export default InfoSection;
