import React, { ReactNode } from 'react';
import Container from '../Container';
import styles from './Section.module.scss';

interface Props {
    id: number;
    title: string;
    subtitle?: string;
    children?: ReactNode;
}

const Section = ({ id, title, subtitle, children }: Props) => (
    <Container className={styles.wrapper}>
        <div id={`section-${id}`} className={styles.header}>
            <div className={styles.title}>
                <span className={styles.number}>{id}</span> <span>{title}</span>
            </div>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
        {children}
    </Container>
);

export default Section;
