import React from 'react';
import styles from './Citations.module.scss';

const Citations = () => (
    <div className={styles.wrapper}>
        <div className={styles.left}>
            <p>
                [1] Campbell, J. P., & Turner, J. E. (2018). Debunking the myth of exercise-induced
                immune suppression: redefining the impact of exercise on immunological health across
                the lifespan. Frontiers in immunology, 9, 648.
            </p>
            <p>
                [2] Bryant, P. A., Trinder, J., & Curtis, N. (2004). Sick and tired: does sleep have
                a vital role in the immune system?. Nature Reviews Immunology, 4(6), 457-467.
            </p>
            <p>
                [3] Zhou, M., & Lin, W. (2016). Adaptability and life satisfaction: The moderating
                role of social support. Frontiers in psychology, 7, 1134.
            </p>
            <p>
                [4] Rockliff, H. et al (2008) &apos;A pilot exploration of heart rate variability
                and salivary cortisol responses to compassion-focused imagery.&apos;, Clinical
                Neuropsychiatry: Journal of Treatment Evaluation, 5(3), pp. 132-139.
            </p>
            <p>
                [5] Tugade, M. M., Fredrickson, B. L., & Feldman Barrett, L. (2004). Psychological
                resilience and positive emotional granularity: Examining the benefits of positive
                emotions on coping and health. Journal of personality, 72(6), 1161-1190.
            </p>
            <p>
                [6] Sergeant, S., & Mongrain, M. (2014). An online optimism intervention reduces
                depression in pessimistic individuals. Journal of consulting and clinical
                psychology, 82(2), 263.
            </p>
            <p>
                [7] Schreiner, I., & Malcolm, J. P. (2008). The benefits of mindfulness meditation:
                Changes in emotional states of depression, anxiety, and stress. Behaviour Change,
                25(3), 156-168.
            </p>
            <p>
                [8] Nistor, A. (2011). Developments on the Happiness Issue: a Review of the Research
                on Subjective Well-being and Flow. Scientific Journal Of Humanistic Studies, 3(4),
                58-66.
            </p>
            <p>
                [9] Goyal, M., Singh, S., Sibinga, E. M., Gould, N. F., Rowland-Seymour, A., Sharma,
                R., ... & Ranasinghe, P. D. (2014). Meditation programs for psychological stress and
                well-being: a systematic review and meta-analysis. JAMA internal medicine, 174(3),
                357-368.
            </p>
            <p>
                [10] Leigh-Hunt, N., Bagguley, D., Bash, K., Turner, V., Turnbull, S., Valtorta, N.,
                & Caan, W. (2017). An overview of systematic reviews on the public health
                consequences of social isolation and loneliness. Public Health, 152, 157-171.
            </p>
            <p>
                [11] Joireman, J. A., III, L. P., & Hammersla, J. (2002). Empathy and the
                self-absorption paradox: Support for the distinction between self-rumination and
                self-reflection. Self and Identity, 1(1), 53-65.
            </p>
            <p>
                [12] Mayer, F. S., Frantz, C. M., Bruehlman-Senecal, E., & Dolliver, K. (2009). Why
                is nature beneficial? The role of connectedness to nature. Environment and behavior,
                41(5), 607-643.
            </p>
            <p>
                [13] Wood, A. M., Froh, J. J., & Geraghty, A. W. (2010). Gratitude and well-being: A
                review and theoretical integration. Clinical psychology review, 30(7), 890-905.
            </p>
            <p>
                [14] Seligman, M. E., Steen, T. A., Park, N., & Peterson, C. (2005). Positive
                psychology progress: empirical validation of interventions. American psychologist,
                60(5), 410.
            </p>
            <p>
                [15] Kerr, S. L., O&apos;Donovan, A., & Pepping, C. A. (2015). Can gratitude and
                kindness interventions enhance well-being in a clinical sample?. Journal of
                Happiness Studies, 16(1), 17-36.
            </p>
        </div>
        <div className={styles.right}>
            <p>
                [16] Eisenberg, N., Smith, C. L., Sadovsky, A., & Spinrad, T. L. (2004). Effortful
                control: Relations with emotion regulation, adjustment, and socialization in
                childhood. In R. F. Baumeister & K. D. Vohs (Eds.), Handbook of self-regulation:
                Research, theory, and applications, 259–282. New York: Guilford Press.
            </p>
            <p>
                [17] Magnuson, C. D., & Barnett, L. A. (2013). The playful advantage: How
                playfulness enhances coping with stress. Leisure Sciences, 35(2), 129-144.
            </p>
            <p>
                [18] Proyer, R. T. (2013). The well-being of playful adults: Adult playfulness,
                subjective well-being, physical well-being, and the pursuit of enjoyable activities.
                The European Journal of Humour Research, 1(1), 84-98.
            </p>
            <p>
                [19] McClure, S. M., Li, J., Tomlin, D., Cypert, K. S., Montague, L. M., & Montague,
                P. R. (2004). Neural correlates of behavioral preference for culturally familiar
                drinks. Neuron, 44(2), 379-387.
            </p>
            <p>
                [20] Fitzsimons, G. M., Chartrand, T. L., & Fitzsimons, G. J. (2008). Automatic
                effects of brand exposure on motivated behavior: how apple makes you “think
                different”. Journal of consumer research, 35(1), 21-35.
            </p>
            <p>
                [21] Amabile, T. M., & Kramer, S. J. (2011). The power of small wins. Harvard
                Business Review, 89(5), 70-80.
            </p>
            <p>
                [22] Ryan, R. M., & Deci, E. L. (2000). Self-determination theory and the
                facilitation of intrinsic motivation, social development, and well-being. American
                Psychologist, 55(1), 68– 78
            </p>
            <p>
                [23] Grisham, J. R., Roberts, L., Cerea, S., Isemann, S., Svehla, J., & Norberg, M.
                M. (2018). The role of distress tolerance, anxiety sensitivity, and intolerance of
                uncertainty in predicting hoarding symptoms in a clinical sample. Psychiatry
                research, 267, 94-101.
            </p>
            <p>
                [24] Wolgast, M., & Lundh, L. G. (2017). Is distraction an adaptive or maladaptive
                strategy for emotion regulation? A person-oriented approach. Journal of
                psychopathology and behavioral assessment, 39(1), 117-127.
            </p>
            <p>
                [25] Baumann, N. (2012). Autotelic personality. In Advances in flow research (pp.
                165-186). Springer, New York, NY.
            </p>
            <p>
                [26] Arida, R. M., & Teixeira-Machado, L. (2021). The contribution of physical
                exercise to brain resilience. Frontiers in Behavioral Neuroscience, 279.
            </p>
            <p>
                [27] Kwak, L., Kremers, S., Walsh, A., & Brug, H. (2006). How is your walking group
                running?. Health Education.
            </p>
            <p>
                [28] White, M.P., Alcock, I., Grellier, J. et al. (2019). Spending at least 120
                minutes a week in nature is associated with good health and wellbeing. Scientific
                Reports, 9, 7730.
            </p>
            <p>
                [29] Coventry, P.A., Brown, J.V.E., Pervin, J., et al. (2021). Nature-based outdoor
                activities for mental and physical health: Systematic review and meta-analysis. SSM
                - Population Health, 16, 100934.
            </p>
            <p>
                [30] Lindsay, E. K., Young, S., Brown, K. W., Smyth, J. M., & Creswell, J. D.
                (2019). Mindfulness training reduces loneliness and increases social contact in a
                randomized controlled trial. Proceedings of the National Academy of Sciences,
                116(9), 3488-3493.
            </p>
            <p>
                [31] Hutcherson, C. A., Seppala, E. M., & Gross, J. J. (2008). Loving-kindness
                meditation increases social connectedness. Emotion, 8(5), 720.
            </p>
        </div>
    </div>
);

export default Citations;
