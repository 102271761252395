import { TypeInsight } from '@/data-access/survey/types';
import classNames from 'classnames';
import styles from './Card.module.scss';
import PlusIcon from '@/svg/plus.svg?component';
import MinusIcon from '@/svg/minus.svg?component';
import { MouseEvent, useState } from 'react';
import marked from '@/utils/marked';
import Truncate from 'react-truncate';
import useIsMobile from '@/hooks/useIsMobile';

const typeText = {
    high: 'Doing great',
    medium: 'Pretty good',
    low: 'Needs Improvement'
};

interface Props {
    title: string;
    text: string;
    type: TypeInsight;
    isMotivationCard?: boolean;
}

const Card = ({ title, text, type, isMotivationCard = false }: Props) => {
    const isMobile = useIsMobile();
    const [isMore, setIsMore] = useState<boolean>(false);
    const handleClickWrapper = () => {
        setIsMore(true);
    };
    const handleClickHeader = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsMore(!isMore);
    };
    const renderText = (
        // eslint-disable-next-line react/no-danger
        <div className={styles.text} dangerouslySetInnerHTML={{ __html: marked(text) }} />
    );
    return (
        <div
            className={classNames(styles.wrapper, styles[`wrapper_${isMore ? 'full' : 'no-full'}`])}
            onClick={handleClickWrapper}
        >
            <div className={styles.header} onClick={handleClickHeader}>
                <div
                    className={classNames({
                        [styles.stacker]: true,
                        [styles.stacker_green]: isMotivationCard
                    })}
                >
                    {title}
                    {!isMotivationCard && (
                        <span className={classNames(styles.type, styles[`type_${type}`])}>
                            {typeText[type]}
                        </span>
                    )}
                </div>
                <div className={styles.space} />
                <button type="button" className={styles.btn}>
                    {isMore ? <MinusIcon /> : <PlusIcon />}
                </button>
            </div>
            {isMore ? (
                renderText
            ) : (
                <Truncate
                    width={isMobile ? 300 : 1074}
                    className={styles.text}
                    trimWhitespace
                    lines={isMobile ? 5 : 2}
                >
                    {renderText}
                </Truncate>
            )}
        </div>
    );
};

export default Card;
