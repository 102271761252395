import React, { PureComponent } from 'react';
import classNames from 'classnames';
import ListItem from './ListItem';
import { Item } from './Autocomplete';

import styles from './Autocomplete.module.scss';

interface Props {
    items: Array<Item>;
    onItemClick: Function;
    className?: string;
}

class List extends PureComponent<Props> {
    render() {
        const { items, onItemClick, className } = this.props;
        const wrapper = classNames(className, styles['list-wrapper']);

        return (
            <div className={wrapper}>
                <div className={styles.stub} />
                <div className={styles.list}>
                    {items.map(item => (
                        <ListItem key={item.label} item={item} onItemClick={onItemClick} />
                    ))}
                </div>
            </div>
        );
    }
}

export default List;
