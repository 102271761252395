import React, { Component } from 'react';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';
import List from './List';
import styles from './Autocomplete.module.scss';
import TriangleUp from '@/svg/icons/triangle-up.svg?component';
import TriangleDown from '@/svg/icons/triangle-down.svg?component';

export interface Item {
    label: string;
    value: number;
}

interface State {
    value: string;
    items: Item[];
    selectedItem?: Item;
    isOpen: boolean;
}

interface Props {
    onChange: Function;
    placeholder: string;
    initialItems: Item[];
    initialItemId?: number;
}

class Autocomplete extends Component<Props, State> {
    input: React.RefObject<any>;

    constructor(props: Props) {
        super(props);

        const initialSelectedItem = props.initialItemId
            ? props.initialItems.find(item => item.value === props.initialItemId)
            : undefined;

        this.state = {
            value: initialSelectedItem ? initialSelectedItem.label : '',
            items: this.props.initialItems,
            isOpen: false,
            selectedItem: initialSelectedItem
        };
        this.input = React.createRef();
    }

    selectItem = (item: Item) => {
        this.setState({
            selectedItem: item,
            value: item.label
        });

        this.props.onChange(item.value);
    };

    sortItems = () => {
        const { value } = this.state;
        const { initialItems } = this.props;

        if (value.length > 0) {
            this.setState({
                items: initialItems.filter(item =>
                    item.label.toLocaleLowerCase().includes(value.toLocaleLowerCase())
                )
            });

            return;
        }

        this.setState({
            items: initialItems
        });
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: event.target.value }, this.sortItems);
    };

    toggleMenu = () => {
        const { isOpen } = this.state;

        if (isOpen) {
            this.closeMenu();

            return;
        }

        this.setState(
            {
                isOpen: true
            },
            this.focus
        );
    };
    // eslint-disable-next-line
    handleClickOutside() {
        // eslint-disable-next-line
        this.closeMenu();
    }

    closeMenu = () => {
        this.setState(
            {
                isOpen: false
            },
            () => {
                this.setState({
                    value: this.state.selectedItem ? this.state.selectedItem.label : '' // eslint-disable-line
                });
                this.sortItems();
                this.focusout();
            }
        );
    };

    focus = () => {
        if (this.input.current) {
            this.input.current.focus();
        }
    };

    focusout = () => {
        if (this.input.current) {
            this.input.current.blur();
        }
    };

    render() {
        const { value, items, isOpen } = this.state;
        const { placeholder } = this.props;
        const inputClass = classNames(styles.search);

        return (
            <div className={styles.wrapper} onClick={this.toggleMenu}>
                <div className={styles.input}>
                    <div className={styles['input-wrapper']}>
                        <input
                            ref={this.input}
                            className={inputClass}
                            placeholder={placeholder}
                            onChange={this.handleChange}
                            value={value}
                        />
                    </div>
                    <div className={styles.triangle}>
                        {isOpen ? <TriangleUp /> : <TriangleDown />}
                    </div>
                </div>

                {items.length > 0 && isOpen && <List items={items} onItemClick={this.selectItem} />}

                {items.length === 0 && value.length > 1 && isOpen && (
                    <div className={styles['list-wrapper']}>
                        <div className={styles.stub} />
                        <div className={styles.list}>
                            <div
                                className={classNames(
                                    styles['list-item'],
                                    styles['list-item--disabled']
                                )}
                            >
                                <div className={styles['list-item--title']}>No results found</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default enhanceWithClickOutside(Autocomplete);
