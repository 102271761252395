/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '@/store';
import { GamesGroup } from './types';
import { mapToStateGamesGroup } from './mappers';
import assessmentRepository from '@/data-access/assessment/assessment.repository';
import initialState from './initialState';

const traitsSlice = createSlice({
    name: 'recommendedGames',
    initialState,
    reducers: {
        setRecommendedGames(state, action: PayloadAction<Array<GamesGroup>>) {
            state.games = action.payload;
        }
    }
});

// export const {} = traitsSlice.actions;

export const fetchRecommendedGames =
    (traitsIds: Array<string>): AppThunk =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await assessmentRepository.getRecommendedGames(traitsIds);
            const games = response.map(mapToStateGamesGroup);

            dispatch(traitsSlice.actions.setRecommendedGames(games));
        } catch (error) {
            console.error(error);
        }
    };

export default traitsSlice.reducer;
