import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home, Survey, SurveyComplete, UserReport } from '@/main/pages';

export default () => (
    <BrowserRouter>
        <Routes>
            <Route path="/user-report/:sessionId" element={<UserReport />} />
            <Route path="/survey-complete" element={<SurveyComplete />} />
            <Route path="/survey" element={<Survey />} />
            <Route path="/" element={<Home />} />
        </Routes>
    </BrowserRouter>
);
