import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export interface ButtonProps {
    children: any;
    onClick?: Function;
    onMouseOver?: Function;
    onMouseOut?: Function;
    onBlur?: Function;
    onFocus?: Function;
    type?: 'primary' | 'secondary';
    color?: 'blue' | 'lighter-blue' | 'light-blue' | 'white' | 'dark-blue';
    disabled?: boolean;
    shadow?: boolean;
    className?: string;
    text?: string;
}

export default class Button extends Component<ButtonProps> {
    static defaultProps = {
        onClick: () => {},
        onMouseOver: () => {},
        onMouseOut: () => {},
        onFocus: () => {},
        onBlur: () => {},
        shadow: true,
        type: 'primary',
        color: 'blue',
        text: 'Default text...',
        disabled: false
    };

    handleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    handleMouseOver = () => {
        const { onMouseOver } = this.props;
        if (onMouseOver) {
            onMouseOver();
        }
    };

    handleMouseOut = () => {
        const { onMouseOut } = this.props;
        if (onMouseOut) {
            onMouseOut();
        }
    };

    handleFocus = () => {
        const { onFocus } = this.props;
        if (onFocus) {
            onFocus();
        }
    };

    handleBlur = () => {
        const { onBlur } = this.props;
        if (onBlur) {
            onBlur();
        }
    };

    render() {
        const { disabled, children, color, type, shadow, className } = this.props;
        return (
            <button
                type="button"
                className={classNames(
                    styles.button,
                    styles[`button--type-${type}`],
                    styles[`button--color-${color}`],
                    {
                        [styles.shadow]: shadow
                    },
                    className
                )}
                onClick={this.handleClick}
                onFocus={this.handleFocus}
                onMouseOver={this.handleMouseOver}
                onMouseOut={this.handleMouseOut}
                onBlur={this.handleBlur}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }
}
