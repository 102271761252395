import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './Slider.module.scss';

const resources = [
    {
        author: 'Arida & Teixeira-Machado (2021)',
        source: 'Frontiers in behavioral neuroscience',
        title: 'Regular exercise helps the brain to be more resilient against cognitive impairment.',
        link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7874196/'
    },
    {
        author: 'Harvanek et al. (2021)',
        source: 'Translational Psychiatry',
        title: 'Psychological resilience and ability to regulate emotions prevents some of the negative impacts of stress in our bodies.',
        link: 'https://www.nature.com/articles/s41398-021-01735-7'
    },
    {
        author: 'Martínez-Martí & Ruch (2017)',
        source: 'The Journal of Positive Psychology',
        title: 'Knowing one’s values and acting based on them positively predicts psychological resilience.',
        link: 'https://www.tandfonline.com/doi/abs/10.1080/17439760.2016.1163403'
    }
];

export default () => (
    <section className={styles.slider}>
        <div className="container">
            <div className={styles.content}>
                <h3 className={styles.title}>
                    Recommended resources about resilience and well-being
                </h3>
                <div className={styles.cards}>
                    {resources.map(resource => (
                        <div key={resource.title} className={styles.card}>
                            <div className={styles.card__name}>
                                {resource.author},{' '}
                                <span className={styles.card__source}>{resource.source}</span>.
                            </div>
                            <div className={styles.card__title}>
                                <a target="_blank" rel="noopener noreferrer" href={resource.link}>
                                    {resource.title}
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </section>
);
