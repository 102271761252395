import { Component } from 'react';
import classNames from 'classnames';
import styles from './Header.module.scss';
import RevisitReportBanner from './RevisitReportBanner';

export interface Props {
    clear?: boolean;
    small?: boolean;
}

export default class Header extends Component<Props> {
    clickLogo = () => {
        window.location.href = './';
    };

    handleStartSurveyClick = () => {
        sessionStorage.setItem('startSession', 'now');
        sessionStorage.removeItem('session');
        sessionStorage.removeItem('answers');
        window.location.href = '/survey';
    };

    render() {
        const { clear, small } = this.props;
        if (clear) {
            return (
                <header
                    className={classNames([
                        styles.header,
                        styles['header--clear'],
                        small && styles['header--small']
                    ])}
                >
                    <div className="container">
                        <div onClick={this.clickLogo} className={styles.logo}>
                            <img src="/assets/images/solstein.png" alt="Solstein logo" />
                        </div>
                    </div>
                </header>
            );
        }

        return (
            <header className={classNames(styles.header, styles['header--with-bg'])}>
                <RevisitReportBanner />
                <div className="container">
                    <div onClick={this.clickLogo} className={styles['logo--full-width']}>
                        <img src="/assets/images/logo.png" alt="Solsten logo" />
                    </div>

                    <h1 className={styles.head}>
                        Experiencing difficult times? You are not alone.
                    </h1>
                    <p className={styles.header__text}>
                        Stress is normal. It’s part of what makes us human. However, stress impacts
                        us all differently. Discover what you uniquely need with tailor-made
                        suggestions.
                    </p>
                    <button
                        type="button"
                        onClick={this.handleStartSurveyClick}
                        className={styles.header__button}
                    >
                        Take assessment
                    </button>
                </div>
            </header>
        );
    }
}
