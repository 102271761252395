import styles from './MotivationProfile.module.scss';
import MountainsIcon from '@/svg/motivation-mountains.svg?component';
import MountainsIconMobile from '@/svg/motivation-mountains-mobile.svg?component';
import { Insight } from '@/data-access/survey/types';
import Card from '../../Card';
import useIsMobile from '@/hooks/useIsMobile';

interface Props {
    highest: [Insight, Insight, Insight];
    lowest: [Insight, Insight, Insight];
}

const MotivationProfile = ({ highest, lowest }: Props) => {
    const isMobile = useIsMobile();

    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>
                These are your most powerful motivators. Harness them to accomplish your goals and
                improve the core wellbeing areas you want to work on.{' '}
            </div>
            {isMobile ? (
                <>
                    {highest.map((item, index) => (
                        <Card
                            title={`${index + 1}. ${item.label}`}
                            text={item.description}
                            isMotivationCard
                            key={item.traitId}
                        />
                    ))}
                    <MountainsIconMobile className={styles.mountains__icon} />

                    <div className={styles.tooltip}>
                        <svg viewBox="0 0 382 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 26.1006C0 19.4732 5.37258 14.1006 12 14.1006H95.5H174.518L177.64 0L189.021 14.1006H370C376.627 14.1006 382 19.4732 382 26.1006V81.7396V94.6006V114.607C382 119.794 377.794 124 372.607 124H12C5.37258 124 0 118.627 0 112V26.1006Z"
                                fill="#013A68"
                            />
                        </svg>
                        <div className={styles.tooltip__text}>
                            {' '}
                            These motivations are not as helpful to you at this time, but could be
                            in the future as your life changes.
                        </div>
                    </div>
                    <div className={styles.items}>
                        {lowest.map(item => (
                            <div key={item.label} className={styles.items__item}>
                                <div className={styles.items__title}>{item.label}</div>
                                <div className={styles.items__content}>{item.description}</div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.list}>
                        {highest.map((item, index) => (
                            <div key={item.label} className={styles.list__item}>
                                <div className={styles['list__item-header']}>
                                    {index + 1}. {item.label}
                                </div>
                                <div className={styles['list__item-content']}>
                                    {item.description}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.mountains}>
                        <MountainsIcon className={styles.mountains__icon} />
                        <div className={styles['mountains-content']}>
                            <div className={styles.tooltip}>
                                <svg
                                    width="511"
                                    height="168"
                                    viewBox="0 0 511 168"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0 30C0 13.4315 13.4315 0 30 0H429C445.569 0 459 13.4315 459 30V72.9876C459 82.9945 463.989 92.3424 472.303 97.9118L510.5 123.5H459V138C459 154.569 445.569 168 429 168H30C13.4315 168 0 154.569 0 138V30Z"
                                        fill="#013A68"
                                    />
                                </svg>
                                <div className={styles.tooltip__text}>
                                    {' '}
                                    These motivations are not as helpful to you at this time, but
                                    could be in the future as your life changes.
                                </div>
                            </div>
                            <div className={styles.items}>
                                {lowest.map(item => (
                                    <div key={item.label} className={styles.items__item}>
                                        <div className={styles.items__title}>{item.label}</div>
                                        <div className={styles.items__content}>
                                            {item.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default MotivationProfile;
