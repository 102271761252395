import React, { Component } from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import styles from './Pagination.module.scss';

interface Props {
    buttons: Array<string | JSX.Element>;
    noBorder?: boolean;
    active?: number;
    onClick: Function;
    className?: string;
    buttonClassName?: string;
    activeLabel?: string;
}

export default class ButtonsGroup extends Component<Props> {
    handleClick = (buttonIndex: number) => {
        const { onClick } = this.props;
        onClick(buttonIndex);
    };

    render() {
        const { buttons, noBorder, active, className, buttonClassName, activeLabel } = this.props;
        return (
            <div className={classNames([styles['buttons-group'], className])}>
                {buttons.map((title, index) => (
                    <Button
                        className={classNames(
                            styles.button,
                            {
                                [styles['button--no-border']]: noBorder,
                                [styles['button--active']]: active === index
                            },
                            buttonClassName
                        )}
                        color={active === index ? 'dark-blue' : 'light-blue'}
                        key={title.toString()}
                        onClick={() => this.handleClick(index)}
                    >
                        {title}
                    </Button>
                ))}
                {activeLabel && active !== undefined && (
                    <span
                        className={styles['active-label']}
                        style={{ left: `${100 * (active / buttons.length)}%` }}
                    >
                        {activeLabel}
                    </span>
                )}
            </div>
        );
    }
}
