import { useState, useEffect } from 'react';
import debounce from 'debounce';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 415);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', debounce(handleResize, 300));

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (
            (windowWidth > 0 && windowWidth <= 415) ||
            (window.screen.width > 0 && window.screen.width <= 415)
        ) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [windowWidth]);

    return isMobile;
};

export default useIsMobile;
