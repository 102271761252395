import { connect } from 'react-redux';
import {
    createSession,
    setSession,
    fetchQuestions,
    setCurrentQuestion,
    setAnswer,
    setAnswers,
    setCompletedTest,
    reset
} from '@/store/survey/slice';
import {
    getQuestions,
    getSession,
    getCurrentQuestion,
    getAnswers,
    isCompletedTest
} from '@/store/survey/selectors';
import { RootState } from '@/store/rootReducer';
import SurveySection from './SurveySection';

const mapStateToProps = (state: RootState) => ({
    questions: getQuestions(state),
    session: getSession(state),
    currentQuestion: getCurrentQuestion(state),
    answers: getAnswers(state),
    isCompleted: isCompletedTest(state)
});

const mapDispatchToProps = {
    createSession,
    fetchQuestions,
    setCurrentQuestion,
    setAnswer,
    setSession,
    setAnswers,
    setCompletedTest,
    reset
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveySection);
