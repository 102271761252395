import React from 'react';
import LogoIcon from '@/svg/logo.svg?component';
import styles from './Header.module.scss';
import Container from '../Container';

interface Props {
    isInGameSurvey?: boolean;
}

const Header = ({ isInGameSurvey = false }: Props) => {
    if (isInGameSurvey) {
        return (
            <Container className={styles['wrapper-ingamesurvey']}>
                <LogoIcon className={styles.logo} />
                <h1 className={styles.title}>
                    Your Wellbeing & <br /> Emotional Resilience Report
                </h1>
            </Container>
        );
    }

    return (
        <Container className={styles.wrapper}>
            <LogoIcon className={styles.logo} />
            <h1 className={styles.title}>
                Your Wellbeing & <br /> Emotional Resilience Report
            </h1>
            <h2 className={styles.subtitle}>
                Your current wellbeing & emotional resilience score:{' '}
                <span className={styles.improvement}>Needs Improvement</span>
            </h2>
            <p className={styles.text}>
                Adaptability and resilience can change over time. If you want to improve your score,
                follow the suggestions below for your core areas of improvement. Come back and
                retake the test in a couple weeks to see how things are going.
            </p>
        </Container>
    );
};

export default Header;
