import { SurveyState } from './types';

const initialState: SurveyState = {
    session: null,
    questions: null,
    answers: null,
    loading: false,
    error: false,
    errorMsg: '',
    currentQuestion: null,
    isCompleted: false
};

export default initialState;
