/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '@/store';
import { Question, Session, Answer } from './types';
import surveyRepository from '@/data-access/survey/survey.repository';
import initialState from './initialState';
import { mapQuestionsToAnswers, getAnswerType } from './mappers';

const surveySlice = createSlice({
    name: 'questions',
    initialState,
    reducers: {
        setQuestions(state, action: PayloadAction<Array<Question>>) {
            state.questions = action.payload;
        },
        setAnswers(
            state,
            action: PayloadAction<{
                answers: Array<Answer>;
                withoutAnswers?: boolean;
            }>
        ) {
            if (action.payload.withoutAnswers) {
                if (state.questions?.length !== state.answers?.length) {
                    state.answers = action.payload.answers;
                }
            } else {
                state.answers = action.payload.answers;
            }
        },
        setSession(state, action: PayloadAction<Session>) {
            state.session = action.payload;
        },
        setCurrentQuestion(state, action: PayloadAction<number>) {
            state.currentQuestion = action.payload;
        },
        setAnswer(state, action: PayloadAction<{ questionIndex: number; newValue: Answer }>) {
            if (!state.answers || !state.questions) {
                return;
            }

            const type = getAnswerType(state.questions[action.payload.questionIndex].type);

            state.answers = state.answers
                ? state.answers.map((answer: Answer, index: number) =>
                      index === action.payload.questionIndex
                          ? {
                                ...answer,
                                [type]: action.payload.newValue
                            }
                          : answer
                  )
                : [];
        },
        setCompleted(state, action: PayloadAction<boolean>) {
            state.isCompleted = action.payload;
        },
        reset: () => initialState
    }
});

export const createSession = (): AppThunk => async (dispatch: AppDispatch) => {
    try {
        const response = await surveyRepository.createSession();
        const session = response;

        dispatch(surveySlice.actions.setSession(session));
        sessionStorage.setItem('startSession', Math.floor(Date.now() / 1000).toString());
        sessionStorage.setItem('session', JSON.stringify(session));
        sessionStorage.removeItem('currentQuestion');
        sessionStorage.removeItem('answers');
    } catch (error) {
        console.error(error);
    }
};

export const setSession =
    (session: Session): AppThunk =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(surveySlice.actions.setSession(session));
        } catch (error) {
            console.error(error);
        }
    };

export const setAnswers =
    (answers: Array<Answer>): AppThunk =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(surveySlice.actions.setAnswers({ answers, withoutAnswers: false }));
        } catch (error) {
            console.error(error);
        }
    };

export const fetchQuestions =
    (withoutAnswers?: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
        try {
            const response = await surveyRepository.getQuestions();
            const questions = response;

            dispatch(surveySlice.actions.setQuestions(questions));
            dispatch(
                surveySlice.actions.setAnswers({
                    answers: mapQuestionsToAnswers(questions),
                    withoutAnswers
                })
            );
        } catch (error) {
            console.error(error);
        }
    };

export const setCurrentQuestion =
    (currentQuestion: number): AppThunk =>
    async (dispatch: AppDispatch) => {
        dispatch(surveySlice.actions.setCurrentQuestion(currentQuestion));
    };

export const setAnswer =
    (currentQuestion: number, newValue: Answer): AppThunk =>
    async (dispatch: AppDispatch) => {
        dispatch(
            surveySlice.actions.setAnswer({
                questionIndex: currentQuestion,
                newValue
            })
        );
    };

export const setCompletedTest =
    (isCompleted: boolean): AppThunk =>
    async (dispatch: AppDispatch) => {
        dispatch(surveySlice.actions.setCompleted(isCompleted));
    };

export const { reset } = surveySlice.actions;

export default surveySlice.reducer;
