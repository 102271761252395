import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './TextInput.module.scss';

export interface InputProps {
    value: string;
    onChange: Function;
    placeholder?: string;
    className?: string;
    maxLength?: number;
}

export default class TextInput extends Component<InputProps> {
    static defaultProps = {
        maxLength: 255
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        onChange(event.target.value);
    };

    render() {
        const { value, placeholder, className, maxLength } = this.props;
        return (
            <input
                className={classNames(styles.input, className)}
                type="text"
                value={value}
                placeholder={placeholder}
                onChange={this.handleChange}
                maxLength={maxLength}
                title={value || placeholder}
            />
        );
    }
}
