import React, { PureComponent } from 'react';
import Checkbox from '@/svg/icons/checkbox.svg?component';
import CheckedMark from '@/svg/icons/checked-mark.svg?component';
import { Item } from './MultiSelect';
import styles from './MultiSelect.module.scss';

interface Props {
    item: Item;
    onItemClick: Function;
    isChecked: boolean;
    isDisabled: boolean;
    singleSelect: boolean;
}

class ListItem extends PureComponent<Props> {
    onItemClick = () => {
        const { item, onItemClick, isDisabled } = this.props;

        if (isDisabled) {
            return;
        }

        onItemClick(item.value);
    };

    getCheckbox() {
        const { singleSelect, isChecked } = this.props;
        if (singleSelect) {
            return isChecked ? (
                <>
                    <CheckedMark className={styles['list-item--checked-icon']} />
                    <Checkbox />
                </>
            ) : null;
        }
        return (
            <div>
                {isChecked ? (
                    <CheckedMark className={styles['list-item--checked-icon']} />
                ) : (
                    <Checkbox className={styles['list-item--checkbox']} />
                )}
            </div>
        );
    }

    render() {
        const { item } = this.props;

        return (
            <div className={styles['list-item']} onClick={this.onItemClick}>
                <div className={styles['list-item--title']}>{item.label}</div>
                <div className={styles['list-item--icon-wrapper']}>{this.getCheckbox()}</div>
            </div>
        );
    }
}

export default ListItem;
