import React from 'react';
import styles from './About.module.scss';

const About = () => (
    <>
        <p className={styles.text}>
            First off, thanks for letting us be a part of your journey - we hope this helps during
            these challenging times.{' '}
        </p>
        <p className={styles.text}>
            At Solsten we believe the path to a better future lies in unlocking the true potential
            of humankind. As a part of that mission, we are doing everything we can to help with the
            mental health aspects of COVID-19 that are often swept under the rug.
        </p>
        <p className={styles.text}>
            Our team of psychologists, physicists, and engineers are passionate about using AI for
            good so we can create technology that enables empathetic human-centric experiences that
            are optimized for human potential and health. We are firm believers that technology
            should adapt to human beings and not the other way around. As such, we want to help you
            and your loved ones to stay healthy, happy, and wise through all of this.
        </p>
        <p className={styles.text}>
            Now that you’ve put your oxygen mask on first, it’s time to assist others. Do a good
            service and share this with the people you care about.{' '}
        </p>
    </>
);

export default About;
