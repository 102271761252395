import classNames from 'classnames';
import React, { ReactNode } from 'react';
import styles from './Container.module.scss';

interface Props {
    children?: ReactNode;
    className?: string;
}

const Container = ({ className, children }: Props) => (
    <div className={classNames(styles.container, className)}>{children}</div>
);

export default Container;
