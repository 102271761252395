import { TraitsState } from './types';

const initialState: TraitsState = {
    groups: [],
    selectedCount: 0,
    loading: false,
    error: false,
    errorMsg: ''
};

export default initialState;
