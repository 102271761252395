export const sections = [
    'Your overall wellbeing',
    'Your psychological wellbeing',
    'Your resilience',
    'Your adaptability to difficult situations',
    'Your self-regulation',
    'Your self-awareness',
    'Your mental agility',
    'Your strength of character',
    'Your social well-being',
    'Your optimism',
    'Your emotional state',
    'Your motivation profile',
    'Your Solsten unique identifier',
    'About Solsten',
    'Citations'
];

export const sectionsSubTitles = [
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'COVID-19 has forced the world to undergo massive change in a short time. Understand how well you’re able to adapt to these changes. There is no right or wrong here. It’s important to acknowledge where we are at, be compassionate with ourselves, and do the best for who we are.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    'Awareness is the first step towards changing the things that are holding you back. Understand how your wellbeing currently stands so you can take action.',
    "What we think greatly affects how we feel, so it's important to keep your hopes and aspirations  in mind while working on any areas of improvement. ",
    'With the previous suggestions, focus on your top motivators to help you change your behavior and avoid your lowest motivators that could delay your progress.'
];

export const inGameSurveySections = [
    'Your motivation profile',
    'Your emotional state',
    'Your current wellbeing',
    'Your Solsten unique identifier',
    'About Solsten',
    'Citations'
];

export const inGameSurveySectionsSubTitles = [
    'Focus on your top motivators to help you change your behavior and avoid your lowest motivators that could delay your progress.',
    "What we think greatly affects how we feel, so it's important to keep your hopes and aspirations  in mind while working on any areas of improvement. ",
    'Focus on your top motivators to help you change your behavior and avoid your lowest motivators that could delay your progress.'
];
