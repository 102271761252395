import React, { PureComponent } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import classNames from 'classnames';
import List from './List';
import styles from './MultiSelect.module.scss';
import CrossIcon from '@/svg/cross.svg?component';
import TriangleDown from '@/svg/icons/triangle-down.svg?component';

export interface Item {
    value: number;
    label: string;
}

interface State {
    isOpen: boolean;
}

interface Props {
    placeholder: string;
    items: Item[];
    maxSelectedValues: number;
    selectedItems: number[];
    onChange: Function;
}

class MultiSelect extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }

    get allItemsAreSelected() {
        const { maxSelectedValues, selectedItems } = this.props;

        if (maxSelectedValues === 1) {
            return false;
        }

        return selectedItems.length === maxSelectedValues;
    }

    // eslint-disable-next-line
    handleClickOutside() {
        // eslint-disable-next-line
        this.closeMenu();
    }

    onInputClick = () => {
        const { isOpen } = this.state;

        this.setState({
            isOpen: !isOpen
        });
    };

    closeMenu = () => {
        this.setState({
            isOpen: false
        });
    };

    onItemClick = (value: number) => {
        const { maxSelectedValues, selectedItems, onChange } = this.props;

        if (selectedItems.includes(value)) {
            onChange(selectedItems.filter(item => item !== value));

            if (maxSelectedValues === 1) {
                this.closeMenu();
                return;
            }

            return;
        }

        if (this.allItemsAreSelected) {
            return;
        }

        onChange(maxSelectedValues === 1 ? [value] : [...selectedItems, value]);

        if (maxSelectedValues === 1) {
            this.closeMenu();
        }
    };

    handleRemoveValue = (value: number) => (e: any) => {
        e.stopPropagation();
        this.onItemClick(value);
    };

    render() {
        const { isOpen } = this.state;
        const { placeholder, items, selectedItems, maxSelectedValues } = this.props;
        const titleClass = classNames(styles.values, {
            [styles['values--placeholder']]: selectedItems.length === 0
        });
        const { allItemsAreSelected } = this;

        return (
            <div className={styles.wrapper}>
                <div onClick={this.onInputClick} className={styles.input}>
                    <div className={titleClass}>
                        {selectedItems.length === 0
                            ? placeholder
                            : items
                                  .filter(item => selectedItems.includes(item.value))
                                  .map(item => (
                                      <span key={item.value} className={styles.values__item}>
                                          {item.label}
                                          <CrossIcon
                                              onClick={this.handleRemoveValue(item.value)}
                                              className={styles.values__icon}
                                          />
                                      </span>
                                  ))}
                    </div>
                    <div
                        className={classNames(styles.triangle, {
                            [styles.triangle_rotate]: isOpen
                        })}
                    >
                        <TriangleDown />
                    </div>
                </div>
                {isOpen && (
                    <List
                        items={items}
                        onItemClick={this.onItemClick}
                        selectedItems={selectedItems}
                        allItemsAreSelected={allItemsAreSelected}
                        singleSelect={maxSelectedValues === 1}
                    />
                )}
            </div>
        );
    }
}

export default enhanceWithClickOutside(MultiSelect);
