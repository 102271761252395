import React, { PureComponent } from 'react';
import ListItem from './ListItem';
import { Item } from './MultiSelect';

import styles from './MultiSelect.module.scss';

interface Props {
    items: Item[];
    onItemClick: Function;
    selectedItems: number[];
    allItemsAreSelected: boolean;
    singleSelect: boolean;
}

class List extends PureComponent<Props> {
    render() {
        const { items, onItemClick, selectedItems, allItemsAreSelected, singleSelect } = this.props;

        return (
            <div className={styles['list-wrapper']}>
                <div className={styles.list}>
                    {items.map(item => {
                        const isChecked = selectedItems.includes(item.value);

                        return (
                            <ListItem
                                key={item.value}
                                item={item}
                                onItemClick={onItemClick}
                                isChecked={isChecked}
                                isDisabled={allItemsAreSelected && !isChecked}
                                singleSelect={singleSelect}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default List;
