import React, { useState, useCallback } from 'react';
import Modal from 'react-modal';
import TextInput from '@/components/TextInput';
import surveyRepository from '@/data-access/survey/survey.repository';

import Close from '@/svg/close-modal.svg?component';

import styles from './index.module.scss';

interface Props {
    onClose: () => void;
}

export default ({ onClose }: Props) => {
    const [identifier, setIdentifier] = useState('');
    const [error, setError] = useState('');
    const clickSubmit = useCallback(async () => {
        try {
            const { data } = await surveyRepository.getSessionId(identifier);

            if (data.session_id) {
                window.open(`/user-report/${data.session_id}`);
            }
            onClose();
        } catch (e) {
            setError('Wrong unique identifier code');
        }
    }, [identifier, onClose]);

    return (
        <Modal
            overlayClassName={styles.modal__overlay}
            isOpen
            onRequestClose={onClose}
            ariaHideApp={false}
            className={styles.modal}
        >
            <div className={styles.header}>
                Access my report{' '}
                <div className={styles['close-wrapper']}>
                    <Close className={styles.close} onClick={onClose} />
                </div>
            </div>
            <div className={styles.description}>
                Already taken the Solsten Home Wellbeing Test? Input the unique identifier code you
                received with your report.{' '}
                <span>Make sure to re-take the test in a month to see how you are improving!</span>
            </div>
            <TextInput
                onChange={setIdentifier}
                placeholder="Unique identifier"
                value={identifier}
                className={styles.input}
            />
            <div className={styles.description}>
                If you have lost or forgotten the code, we have no way to retrieve it or your
                results, although you can retake the test should you forget.
            </div>
            <button
                type="button"
                onClick={clickSubmit}
                disabled={!identifier}
                className={styles.button}
            >
                Submit
            </button>

            {error && <div className={styles.error}>{error}</div>}
        </Modal>
    );
};
