import React, { PureComponent } from 'react';
import styles from './Autocomplete.module.scss';
import { Item } from './Autocomplete';

interface Props {
    item: Item;
    onItemClick: Function;
}

class ListItem extends PureComponent<Props> {
    onItemClick = () => {
        const { item, onItemClick } = this.props;

        onItemClick(item);
    };

    render() {
        const { item } = this.props;

        return (
            <div className={styles['list-item']} onClick={this.onItemClick}>
                <div className={styles['list-item--title']}>{item.label}</div>
            </div>
        );
    }
}

export default ListItem;
