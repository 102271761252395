import { RootState } from '@/store/rootReducer';
import { Question, Session, Answer } from './types';

export const getSession = (state: RootState): Session | null => state.survey.session;

export const getQuestions = (state: RootState): Array<Question> | null => state.survey.questions;

export const getCurrentQuestion = (state: RootState): number | null => state.survey.currentQuestion;

export const getAnswers = (state: RootState): Array<Answer> | null => state.survey.answers;

export const isCompletedTest = (state: RootState): boolean => state.survey.isCompleted;
