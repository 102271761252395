import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import surveyRepository from '@/data-access/survey/survey.repository';

import styles from './index.module.scss';
import Header from './Header';
import { ReportData } from '@/data-access/survey/types';
import LegendSection from './LegendSection';
import Section from './Section';
import { configSections, inGameSurveyConfigSections } from './config';
import Spinner from '@/components/Spinner';

type ValueGroups = {
    [K in typeof VALUE_GROUPS[number]]: string[];
};

const VALUE_GROUPS = ['low', 'medium', 'high'] as const;

const PRIORITY_LIST: { [key: string]: number } = {
    RESILIENCE_TOTAL: 1,
    RESILIENCE_AGILITY_TOTAL: 2,
    RESILIENCE_CHARACTER_TOTAL: 3,
    RESILIENCE_OPTIMISM_TOTAL: 4,
    PA_OVERALL_WELLBEING: 5,
    N_PSYCH_WELL_BEING: 6,
    PA_SOCIAL_WELLBEING: 7,
    PANAS_GENERALPOSITIVEEMOTION: 8,
    MINDFULNESS_MEDITATION: 9,
    CONSCIOUSNESS_SELF_AWARENESS: 10,
    CONSCIOUSNESS_SELF_REFLECTION_TOTAL: 11,
    CONSCIOUSNESS_RC_SELF_RUMINATION: 12,
    CONSCIOUSNESS_RC_CONNECT_TO_NATURE: 13,
    CONSCIOUSNESS_RC_GRATITUDE: 14,
    FLOW_RC_DISPOSITION: 15,
    FLOW_STATE: 16,
    NEO_ALTRUISM: 17,
    NEO_FEELING_EMPATHY: 18,
    SOCIAL_SUPPORT: 19,
    SOCIAL_CONTACTS: 20,
    ADAPTABILITY_TOTAL: 21,
    N_RESILIENCE: 22,
    SELF_CARE_RELAXATION: 23
};

const getTopInsights = (data: ReportData) => {
    const topInsights: ValueGroups = { low: [], medium: [], high: [] };

    const priorityValues = Object.values(data?.insights).filter(value =>
        Object.keys(PRIORITY_LIST).includes(value.traitId)
    );

    VALUE_GROUPS.map(valueGroup => {
        topInsights[valueGroup] = priorityValues
            ?.filter(item => item.valueGroup === valueGroup)
            .sort((a, b) => PRIORITY_LIST[b.traitId] - PRIORITY_LIST[a.traitId])
            .map(item => item.label)
            .slice(0, 3);
        return topInsights;
    });

    return topInsights;
};

const UserReport = () => {
    const [data, setData] = useState<ReportData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [topInsights, setTopInsights] = useState<ValueGroups>({ low: [], medium: [], high: [] });
    const params = useParams<{ sessionId: string }>();

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const { data } = await surveyRepository.getUserReport(params.sessionId || '');
                setData(data);
                setIsLoading(false);
            } catch (err) {
                setIsLoading(false);
                setHasError(true);
            }
        })();
    }, []);

    useEffect(() => {
        if (data) {
            setTopInsights(getTopInsights(data));
        }
    }, [data]);

    if (isLoading) {
        return (
            <div className={styles.loader}>
                <Spinner width={30} height={30} />
                <div className={styles.loader__title}>Loading...</div>
            </div>
        );
    }

    if (hasError) {
        return <>Invalid session id</>;
    }

    if (data?.isInGameSurvey) {
        return (
            data && (
                <div className={styles.wrapper}>
                    <Header isInGameSurvey />
                    <LegendSection topInsights={topInsights} isInGameSurvey />
                    {inGameSurveyConfigSections(data).map(
                        ({ id, title, subTitle, Component, props }) => (
                            <Section key={id} id={id} title={title} subtitle={subTitle}>
                                <Component {...props} />
                            </Section>
                        )
                    )}
                </div>
            )
        );
    }

    return (
        data && (
            <div className={styles.wrapper}>
                <Header />
                <LegendSection topInsights={topInsights} />
                {configSections(data).map(({ id, title, subTitle, Component, props }) => (
                    <Section key={id} id={id} title={title} subtitle={subTitle}>
                        <Component {...props} />
                    </Section>
                ))}
            </div>
        )
    );
};

export default UserReport;
