import styles from './index.module.scss';
import ReportScreenIcon from '@/svg/report-screenshot.svg?component';

const handleStartSurveyClick = (): void => {
    sessionStorage.setItem('startSession', 'now');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('answers');
    window.location.href = '/survey';
};

const TakeReportSection = () => (
    <div className={styles.wrapper}>
        <div className="container">
            <div className={styles.content}>
                <h3 className={styles.title}>
                    An empirically valid assessment from anywhere in the world.
                </h3>
                <p className={styles.subtitle}>
                    No personally identifiable information is asked, required, or recorded.
                </p>

                <ReportScreenIcon className={styles.content__image} />
                <button type="button" onClick={handleStartSurveyClick} className={styles.button}>
                    Get your report
                </button>
            </div>
        </div>
    </div>
);

export default TakeReportSection;
