import axios from 'axios';
import { ServerResponse, Game } from './types';
import { ROUTES } from './routes';

export default class ApiGames {
    _config = {
        baseURL: process.env.REACT_APP_GAMES_SEARCH_API_URL,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_GAMES_SEARCH_API_KEY}`
        }
    };

    async _get<T>(route: string, params?: any) {
        // if (params && params.query) { params.query = `${params.query}&datatype=game`};
        const paramObject = params
            ? {
                  ...this._config,
                  params
              }
            : this._config;

        const response = await axios.get<ServerResponse<T>>(route, paramObject);

        return response.data;
    }

    async getGames(gameName: string) {
        const response = await this._get<Game[]>(ROUTES.GAMES, {
            query: gameName,
            datatype: 'game',
            api_key: process.env.REACT_APP_GAMES_SEARCH_API_KEY
        });

        return response.data;
    }
}
