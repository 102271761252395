import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './Spinner.module.scss';

interface Props {
    width?: string | number;
    height?: string | number;
    className?: string;
}

export default ({ width = 19, height = 19, className = '' }: Props): ReactElement => (
    <svg
        width={width}
        height={height}
        className={classNames(styles.spinner, className)}
        viewBox="-25 -25 50 50"
    >
        <circle cx="0" cy="0" r="20" strokeWidth="6" strokeMiterlimit="10" />
    </svg>
);
