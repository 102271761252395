import React from 'react';
import classNames from 'classnames';
import styles from './SurveyResults.module.scss';
import PenguinIcon from '@/svg/red-penguin.svg?component';
import WhiteScreen from '../WhiteScreen';
import Button from '@/components/Button';
import SurveyFooter from '../../SurveyFooter/SurveyFooter';

interface Props {
    onClick: Function;
}

export default ({ onClick }: Props) => {
    const handleClick = () => {
        onClick();
    };

    return (
        <WhiteScreen confetti>
            <div className={classNames('container', styles.content)}>
                <div className={styles.container}>
                    <h2 className={styles.title}>Well done, your results are ready!</h2>
                    <div className={styles.text}>
                        We’ve analyzed your results and created personalized recommendations - just
                        for you!
                        <br />
                        <div>
                            Make sure to save your report link so you can revisit it later or share
                            it!
                        </div>
                    </div>
                    <div className={styles.button_wrapper}>
                        <Button
                            type="secondary"
                            color="light-blue"
                            onClick={handleClick}
                            className={styles['fullwidth-button']}
                        >
                            Go to my personal report
                        </Button>
                        <PenguinIcon className={styles.penguin} />
                    </div>
                </div>
            </div>
            <SurveyFooter />
        </WhiteScreen>
    );
};
