import React from 'react';
import styles from './ProgressBar.module.scss';

interface Props {
    currentQuestion: number;
    totalQuestions: number;
}

const ProgressBar = ({ currentQuestion, totalQuestions }: Props) => {
    const trackStyles = {
        width: `${(currentQuestion / totalQuestions) * 100}%`
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.label}>
                question {currentQuestion} of {totalQuestions}
            </div>
            <div className={styles.track}>
                <div className={styles.track__progress} style={trackStyles} />
            </div>
        </div>
    );
};

export default ProgressBar;
