import React from 'react';
import classNames from 'classnames';
import { Question, Answer } from '@/store/survey/types';
import Pagination from '@/components/Pagination';
import Button from '@/components/Button';
import ArrowIcon from '@/svg/arrow.svg?component';
import styles from './SurveySection.module.scss';
import GamesAutocomplete from '@/components/GamesAutocomplete';
import TextInput from '@/components/TextInput';
import MultiSelect from '@/components/MultiSelect';
import Autocomplete from '@/components/Autocomplete';

export interface Props {
    isEmptyAnswer: boolean;
    answer: any;
    question: Question;
    onPrev?: Function;
    onNext: Function;
    onChange: Function;
}

export default ({ isEmptyAnswer, question, answer, onPrev, onNext, onChange }: Props) => {
    const likertButtons = [1, 2, 3, 4, 5, 6, 7];

    const getValueByType = () => {
        // TODO: https://12traits.atlassian.net/browse/DASH-2237 !!!
        if (question.id === 102) {
            return (
                question.options && (
                    <div
                        className={classNames(
                            styles['input-wrapper'],
                            styles['input-wrapper--column']
                        )}
                    >
                        <div className={styles.flex}>
                            <Autocomplete
                                placeholder={question.instructions}
                                initialItems={question.options.map(option => ({
                                    label: option.label,
                                    value: option.id
                                }))}
                                initialItemId={answer}
                                onChange={(element: number) => onChange(element)}
                            />
                            <Button
                                color="light-blue"
                                type="secondary"
                                className={styles['next-button']}
                                disabled={isEmptyAnswer}
                                onClick={onNext}
                            >
                                <div className={styles['next-button-title']}>
                                    Next
                                    <ArrowIcon
                                        className={classNames(styles['next-button-arrow'])}
                                    />
                                </div>
                            </Button>
                        </div>
                    </div>
                )
            );
        }

        switch (question.type) {
            case 'likert':
                return (
                    <div className={styles['pagination-wrapper']}>
                        <Pagination
                            active={answer ? likertButtons.indexOf(answer) : undefined}
                            onClick={(index: number) => {
                                onChange(likertButtons[index], true);
                            }}
                            buttons={likertButtons.map(val => val.toString())}
                            className={styles.pagination}
                            noBorder
                        />
                        <div className={styles.labels}>
                            <div
                                className={classNames(
                                    'mobile-shown--flex',
                                    styles['pagination-labels']
                                )}
                            >
                                <span className={styles.start}>{question.likert_start_label}</span>
                                <span className={styles.end}>{question.likert_end_label}</span>
                            </div>
                            <span className={classNames(styles.start, 'mobile-hidden')}>
                                {question.likert_start_label}
                            </span>
                            <span className={classNames(styles.end, 'mobile-hidden')}>
                                {question.likert_end_label}
                            </span>
                        </div>
                    </div>
                );
            case 'dropdown_multiple':
                return (
                    question.options && (
                        <div
                            className={classNames(
                                styles['input-wrapper'],
                                styles['input-wrapper--column']
                            )}
                        >
                            <div className={styles.flex}>
                                <MultiSelect
                                    placeholder="Select an answer"
                                    items={question.options.map(option => ({
                                        label: option.label,
                                        value: option.id
                                    }))}
                                    selectedItems={answer.map((ans: Answer) => ans.id)}
                                    maxSelectedValues={question.options_max}
                                    onChange={(elements: number[]) =>
                                        onChange(
                                            question.options
                                                ?.filter((option: any) =>
                                                    elements.includes(option.id)
                                                )
                                                .map((option: any) => ({
                                                    id: option.id
                                                }))
                                        )
                                    }
                                />

                                {answer.length >= 3 && (
                                    <Button
                                        color="light-blue"
                                        type="secondary"
                                        className={styles['next-button']}
                                        disabled={isEmptyAnswer}
                                        onClick={onNext}
                                    >
                                        <div className={styles['next-button-title']}>Next</div>
                                    </Button>
                                )}
                            </div>
                        </div>
                    )
                );
            case 'dropdown_single':
                return (
                    question.options && (
                        <div
                            className={classNames(
                                styles['input-wrapper'],
                                styles['input-wrapper--column']
                            )}
                        >
                            <div className={styles.flex}>
                                <MultiSelect
                                    placeholder={question.instructions}
                                    items={question.options.map(option => ({
                                        label: option.label,
                                        value: option.id
                                    }))}
                                    selectedItems={answer ? [answer] : []}
                                    maxSelectedValues={1}
                                    onChange={(elements: number[]) =>
                                        onChange(elements.length > 0 ? elements[0] : null)
                                    }
                                />
                                <Button
                                    color="light-blue"
                                    type="secondary"
                                    className={styles['next-button']}
                                    disabled={isEmptyAnswer}
                                    onClick={onNext}
                                >
                                    <div className={styles['next-button-title']}>
                                        Next
                                        <ArrowIcon
                                            className={classNames(styles['next-button-arrow'])}
                                        />
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )
                );

            case 'multiple_text_inputs':
                return (
                    <div
                        className={classNames(
                            styles['input-wrapper'],
                            styles['input-wrapper--column']
                        )}
                    >
                        <p className={styles.label}>Type your favorite brands</p>
                        <div className={styles.flex}>
                            <div className={styles['text-inputs-wrapper']}>
                                {question.options &&
                                    question.options.map((option, index) => (
                                        <TextInput
                                            key={option.id}
                                            value={
                                                answer.length >= index ? answer[index].value : ''
                                            }
                                            onChange={(newVal: string) =>
                                                onChange(
                                                    answer.map((val: any, index1: number) =>
                                                        index1 === index
                                                            ? {
                                                                  ...val,
                                                                  value: newVal
                                                              }
                                                            : val
                                                    )
                                                )
                                            }
                                            placeholder={option.label}
                                            className={styles['text-input']}
                                            maxLength={100}
                                        />
                                    ))}
                            </div>
                            <Button
                                color="light-blue"
                                type="secondary"
                                className={styles['next-button']}
                                disabled={isEmptyAnswer}
                                onClick={onNext}
                            >
                                <div className={styles['next-button-title']}>
                                    Next
                                    <ArrowIcon
                                        className={classNames(styles['next-button-arrow'])}
                                    />
                                </div>
                            </Button>
                        </div>
                    </div>
                );
            case 'single_text_input':
                return (
                    <div
                        className={classNames(
                            styles['input-wrapper'],
                            styles['input-wrapper--column']
                        )}
                    >
                        <p className={styles.label}>Enter your age</p>
                        <div className={styles.flex}>
                            <TextInput value={answer} onChange={(text: string) => onChange(text)} />
                            <Button
                                color="light-blue"
                                type="secondary"
                                className={styles['next-button']}
                                disabled={isEmptyAnswer}
                                onClick={onNext}
                            >
                                <div className={styles['next-button-title']}>
                                    Next
                                    <ArrowIcon
                                        className={classNames(styles['next-button-arrow'])}
                                    />
                                </div>
                            </Button>
                        </div>
                    </div>
                );
            case 'games_autocomplete': {
                const filteredAnswer = answer.filter((ans: Answer) => !!ans.name);

                return (
                    <div
                        className={classNames(
                            styles['input-wrapper'],
                            styles['input-wrapper--column']
                        )}
                    >
                        <p className={styles.label}>
                            {`Choose up to ${question.options_max} favorite games`} (
                            {filteredAnswer.length}/{question.options_max})
                        </p>
                        <div className={styles.flex}>
                            <GamesAutocomplete
                                onChange={(values: string[]) => {
                                    onChange(
                                        values.map((value: string, index: number) => ({
                                            name: value,
                                            value,
                                            id: question.options && question.options[index].id
                                        }))
                                    );
                                }}
                                initialSelectedItems={filteredAnswer}
                            />
                            <Button
                                color="light-blue"
                                type="secondary"
                                className={styles['next-button']}
                                disabled={isEmptyAnswer}
                                onClick={onNext}
                            >
                                <div className={styles['next-button-title']}>
                                    Next
                                    <ArrowIcon
                                        className={classNames(styles['next-button-arrow'])}
                                    />
                                </div>
                            </Button>
                        </div>
                    </div>
                );
            }
            default:
                return false;
        }
    };

    const handlePrevClick = () => {
        if (onPrev) {
            onPrev();
        }
    };

    const handleNextClick = () => {
        if (!isEmptyAnswer) {
            onNext();
        }
    };

    return (
        <div className={styles.question}>
            <div className={styles['question-block']}>
                <div className={styles['header-wrapper']}>
                    <div className={styles.header}>
                        <ArrowIcon
                            className={classNames('mobile-hidden', styles['prev-arrow'], {
                                [styles['prev-arrow--disabled']]: !onPrev
                            })}
                            onClick={handlePrevClick}
                        />
                        <span className={styles.type}>{question.instructions}</span>
                        <ArrowIcon
                            className={classNames('mobile-hidden', styles['next-arrow'], {
                                [styles['next-arrow--disabled']]: isEmptyAnswer
                            })}
                            onClick={handleNextClick}
                        />
                    </div>
                </div>
                <h2 className={styles.title}>{question.summary}</h2>
                {getValueByType()}
            </div>
        </div>
    );
};
