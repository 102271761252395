import React from 'react';
import classNames from 'classnames';
import styles from './WhiteScreen.module.scss';
import WhiteScreen from './WhiteScreen';
import Button from '@/components/Button';
import PenguinIcon from '@/svg/blue-penguin.svg?component';
import SurveyFooter from '../SurveyFooter/SurveyFooter';

interface Props {
    onClick: Function;
}

export default ({ onClick }: Props) => (
    <WhiteScreen>
        <div className={classNames('container', styles.content)}>
            <div className={styles.container}>
                <h2 className={styles.title}>Awesome job, you’re finished!</h2>
                <Button
                    type="secondary"
                    color="light-blue"
                    onClick={onClick}
                    className={styles['fullwidth-button']}
                >
                    Get my results
                </Button>
            </div>
            <div
                className={classNames([
                    styles['donor-suggestion'],
                    styles['donor-suggestion--with-blue']
                ])}
            >
                <p className={classNames([styles.text, styles['text--blue']])}>
                    By clicking “Get my results”, you explicitly consent to Solsten’s use of the
                    data collected in the assessment to analyze psychological traits, to identify
                    user clusters, to recommend games and experiences for you, and as otherwise set
                    forth in the{' '}
                    <a href="https://solsten.io/privacy-policy/">Solsten Privacy Policy</a>. Your
                    participation is voluntary and you may withdraw consent at any time by emailing{' '}
                    <a href="mailto:explore@solsten.io">explore@solsten.io</a>.
                </p>
                <PenguinIcon className={styles.penguin} />
            </div>
        </div>
        <SurveyFooter />
    </WhiteScreen>
);
