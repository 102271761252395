import Container from '../Container';
import styles from './LegendSection.module.scss';
// @ts-ignore
import av from 'animate-value';
import { sections, inGameSurveySections } from '../constants';
import classNames from 'classnames';

interface Props {
    topInsights: {
        low: string[];
        medium: string[];
        high: string[];
    };
    isInGameSurvey?: boolean;
}

const LegendSection = ({ topInsights, isInGameSurvey = false }: Props) => {
    const renderCard = (type: 'great' | 'improve' | 'grateful', title: string, items: string[]) => (
        <div className={classNames(styles.card, styles[`card_${type}`])}>
            <div className={styles.card__header}>{title}</div>
            <ul className={styles.card__list}>
                {items.length ? (
                    items.map(item => (
                        <li key={item} className={styles['card__list-item']}>
                            {`•  ${item}`}
                        </li>
                    ))
                ) : (
                    <li className={styles['card__list-item']}>
                        {type === 'great' &&
                            'Your scores are more neutral or low right now. Check the sections below to learn more.}'}
                        {type === 'improve' &&
                            'Your scores are more neutral or high right now. Check the sections below to learn more.'}
                        {type === 'grateful' &&
                            'Your scores are more high or low right now. Check the sections below to learn more.'}
                    </li>
                )}
            </ul>
        </div>
    );

    const handleClickLink = (id: string) => () => {
        const el = document.getElementById(`section-${id}`);
        av({
            from: window.scrollY,
            to: el?.offsetTop,
            easing: 'easeInQuad',
            duration: 1000,
            change: (value: number) => {
                window.scrollTo(0, value);
            }
        });
    };

    if (isInGameSurvey) {
        return (
            <Container className={styles.wrapper}>
                <div className={styles.left}>
                    <div className={styles.title}>Report Sections</div>
                    <ul className={styles.list__ingamesurvey}>
                        {inGameSurveySections.map((item, index) => (
                            <li key={item} className={styles.list__item}>
                                <span
                                    onClick={handleClickLink(`${index + 1}`)}
                                    className={styles.list__link}
                                >
                                    {item}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            </Container>
        );
    }

    return (
        <Container className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.title}>Report Sections</div>
                <ul className={styles.list}>
                    {sections.map((item, index) => (
                        <li key={item} className={styles.list__item}>
                            <span
                                onClick={handleClickLink(`${index + 1}`)}
                                className={styles.list__link}
                            >
                                {item}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.right}>
                <div className={styles.text}>
                    When events occur that are outside of our control, it helps to look to the ways
                    in which we can empower ourselves. The results of your Solsten Wellbeing &
                    Emotional Resilience Assessment will give you the best tools to stay mentally
                    well and in good spirits despite challenging times.
                </div>
                {renderCard('great', "Where you're doing great", topInsights.high)}
                {renderCard('improve', 'Areas to improve', topInsights.low)}
                {renderCard('grateful', 'What you can be grateful for', topInsights.medium)}
            </div>
        </Container>
    );
};

export default LegendSection;
