import { combineReducers } from '@reduxjs/toolkit';
import traits from './traits/slice';
import recommendedGames from './recommended-games/slice';
import survey from './survey/slice';

const rootReducer = combineReducers({
    traits,
    recommendedGames,
    survey
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
