import classNames from 'classnames';
import styles from './SurveyFooter.module.scss';

const SurveyFooter = () => (
    <div className={styles.footer}>
        <div className={classNames('container', styles['footer-content'])}>
            <nav>
                <ul>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://solsten.io/terms-of-service/"
                        >
                            Terms of service
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://solsten.io/privacy-policy/"
                        >
                            Privacy policy
                        </a>
                    </li>
                </ul>
            </nav>
            <div className={styles['footer-info']}>© 2022 Solsten, Inc. All Rights Reserved.</div>
        </div>
    </div>
);

export default SurveyFooter;
