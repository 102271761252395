import React from 'react';
import Header from '@/main/sections/Header';
import SurveySection from '@/main/sections/SurveySection';
import styles from './Survey.module.scss';
import '@/main/styles/common.scss';
import classNames from 'classnames';
import SurveyFooter from '../sections/SurveyFooter/SurveyFooter';

export default class Survey extends React.PureComponent {
    render() {
        return (
            <div className={classNames([styles.wrapper, styles['wrapper--colored']])}>
                <div className={styles.water_top} />
                <Header small clear />
                <SurveySection />
                <SurveyFooter />
                <div className={styles.water_bottom} />
            </div>
        );
    }
}
