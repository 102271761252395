import { Insight } from '@/data-access/survey/types';
import Card from '../Card';

interface Props {
    data: Insight[];
}

const toBePrefixed = [
    'Resilience',
    'Adaptability',
    'Mental Agility',
    'Strength of Character',
    'Optimism'
];

const getCardTitle = (label: string): string => {
    let newLabel = '';
    if (toBePrefixed.includes(label)) {
        newLabel = `Overall ${label.toLowerCase()}`;
    }
    return newLabel;
};

const Cards = ({ data = [] }: Props) =>
    data.map(item => (
        <Card
            key={item.label}
            title={getCardTitle(item.label)}
            type={item.valueGroup}
            text={item.description}
        />
    ));

export default Cards;
