import React from 'react';
import Trait from './Trait';
import styles from './TraitsSection.module.scss';

const traits = [
    {
        title: 'Self-awareness',
        subtitle: `The ability to be objectively aware of one's thoughts, emotions and beliefs.`,
        expandedData: {
            text: `The ability to be objectively aware of one's thoughts, emotions and beliefs. Increased self-awareness helps us better regulate our moods and, for example, stress. Through increased selfawareness it becomes easier to put things into perspective and take other people's perspectives into account as well. Thus it can greatly improve one's relationships.`,
            listTitle: `Try these tips to increase your self-awareness:`,
            list: [
                'Try mindfulness and meditation techniques. Mindfulness and meditation help you pay attention to your thoughts, surroundings, or your body, and help you become more aware of these thoughts and your feelings and sensations.',
                'Take time in your day to go through your thoughts, feelings and behaviors. You might want to write your thoughts down in a journal or simply think of them quietly.'
            ]
        }
    },
    {
        title: 'Self-regulation',
        subtitle: `The ability to understand and manage one's thoughts, emotions in relation to the desired outcome.`,
        expandedData: {
            text: `The ability to understand and manage one's thoughts, emotions, and behaviors, and to adjust them depending on the demands of the situation and in relation to the desired outcome.`,
            listTitle: `Try these tips to increase your ability to self-regulate:`,
            list: [
                'Shifting from an external locus of control (how other people or outside factors are the reason you are where you are at or feel the way you do) to an internal locus of control (how you are responsible for how you feel and where you are at) is one of the best ways to increase self-regulation. The goal is to learn to take responsibility for situations rather than shifting blame.',
                'If you want to make it simple, one way to improve locus of control and self regulation is to use your opposite hand throughout the day. Try to start with brushing your teeth with the other hand. This has been shown in research to improve self-regulation.',
                'Try mindfulness techniques. Mindfulness helps us be present in the moment. It can help create space between ourselves and our reactions to what is happening around us. This, in turn, can help us stay more focused, calm and relaxed in different situations.',
                'Try to reframe situations and change your emotional reactions to them. Instead of letting yourself get worked up by something, try to see if you can reinterpret the situation from another, more neutral angle.',
                `Attach self-regulation to a larger goal. Emotional regulation is associated with higher levels of well-being, social status, and overall income. Know that when you are not regulating your emotions, you're working against yourself.`
            ]
        }
    },
    {
        title: 'Mental agility',
        subtitle: `The ability to evaluate things and situations from different perspectives, to think creatively and flexibly.`,
        expandedData: {
            text: `The ability to evaluate things and situations from different perspectives, to think creatively and flexibly in order to come up with new solutions and try out new strategies. Being able to look at situations from different angles and exploring different solutions helps us be more resilient during difficult times.`,
            listTitle: `Try these tips to increase your self-awareness:`,
            list: [
                'Identify situations that cause you stress and the emotional and behavioral consequences of these situations. Reflect on your responses to these situation and try to identify which responses are productive and which are counterproductive.',
                'Avoid dwelling on worst-case scenarios. Instead, try to think of multiple, more likely outcomes. Try some breathing exercises. Controlling your breathing can, for example, reduce anxiety and help you to more effectively deal with stressful situations.',
                'Play video games! Especially puzzle, action, or strategy games. Research has shown all of these to improve mental agility. ',
                'Move, walk at least 20 minutes a day, exercise, or better yet, dance.',
                'Computer use and gaming combined with physical activity has been shown to have the great impact together on mental agility. So get your gaming in and your exercise, or play games that get you moving!'
            ]
        }
    },
    {
        title: 'Strength of character',
        subtitle: `The ability to identify one's strengths and weaknesses, and how to use them to manage stressful events.`,
        expandedData: {
            text: `The ability to identify one's strengths and weaknesses, and how to use them to manage stressful events. `,
            listTitle: `Try these tips to work on your character strengths:`,
            list: [
                'Define your values. Character development is not something that happens overnight. It happens through defining and sustaining your values over time. You can do this through values assessments with your local psychologists or by journaling.',
                'Take time to reflect what are your strengths and where do you feel you still have space to grow or room for improvement. We all have our strengths and weaknesses, and when we are aware of them, we can use them to our advantage.',
                'Be honest. As simple as this may sound, being honest correlates with self-control and overall satisfaction with life. ',
                `Journal about how you treat people who can do nothing for you as well as those closest to you that you may take for granted who you know will show up for you no matter what. This is your character. If you don't like it, do the above steps to change it.`
            ]
        }
    },
    {
        title: 'Social connection',
        subtitle: `The ability to build and maintain stong relationships with people around you.`,
        expandedData: {
            text: `The ability to build and maintain stong relationships with people around you.`,
            listTitle: `Try these tips to boost your social connections:`,
            list: [
                'Be present. Abandon your laptop, phone, other distractions, and what is top of mind for you at that time. Being fully present to that other person and the situation for even five minutes not only has incredible health benefits for you, but it also is exactly how connection is created.',
                'Be honest and express frustrations. Relationships where people express frustrations and the other party can handle these frustrations result in healthier and happier relationships over time. ',
                'If possible, try to get to know those around you a bit better. Every one of us wants to feel heard and understood. Approaching other people with curiosity, openness and without judgment might make it easier for both to open up, share more of yourself, and foster a deeper connection.',
                'Try to put yourself into situations where your chances of meeting new people become higher, such as hobbies and activities you enjoy engaging in or meet-ups around themes you find interesting.',
                'Most importantly, nurture your relationship with yourself and seek to truly connect with yourself. Our relationship with ourselves forms the foundation of how we connect with the outside world and other people.'
            ]
        }
    },
    {
        title: 'Optimism',
        subtitle: `An attitude characterized by hope and confidence in positive or desirable outcomes. `,
        expandedData: {
            text: `An attitude characterized by hope and confidence in positive or desirable outcomes. When we approach life with an optimistic attitude, we are more likely to see challenges as chances to grow and learn, and we won't let one bad experience bring us down. Having an optimistic outlook helps us be more resilient when facing difficult times, and conversely, having more resilience allows us to stay more optimistic.`,
            listTitle: `Try these tips to help you have a more optimistic approach:`,
            list: [
                'One of the best ways to increase resilience is to increase positive and optimistic thoughts as research has found. By visualizing and generating positive outcomes, you can reduce levels of anxiety and have less intrusive negative thoughts.',
                `Try some mindfulness and meditation practices. Mindfulness and meditation help you focus on the present and avoid worrying about the future, especially about things you can't control.`,
                'Gratitude adherence is a great way to improve optimism. Make it a practice when you wake up, before dinner with your family, or before you go to bed to name two things your grateful for. The key is making it a routine and doing it at a time that is easiest for you.',
                'When you catch yourself having a very pessimistic thoughts or predictions of outcomes, take note of them, and try to put things into perspective. Based on research, health is actually better predicted by the absence of pessimism than the presence of optimism. A good practice to stop pessimistic thinking is to 1. pause and focus on the world around you with your senses, 2. take notice of the difference between being stuck in your thoughts vs. experiencing the moment, 3. labeling your thoughts which allows you to distance yourself from you from those thoughts and feelings (you are not your thoughts nor your feelings), and 4. set an intention and choose to act in that direction.'
            ]
        }
    }
];

const TraitsSection = () => (
    <section className={styles.section}>
        <div className={styles.content}>
            <div className="container">
                <h2 className={styles.section__title}>
                    Learn what it takes for you to build emotional resilience.
                </h2>
                <p className={styles.section__text}>
                    Emotional resilience shows up in countless areas of our daily lives. Below are a
                    few resilience competencies. Click on each one to see some ways to boost this
                    competency.
                </p>
                <div>
                    {traits.map(trait => (
                        <Trait key={trait.title} trait={trait} />
                    ))}
                </div>{' '}
            </div>
            <div className={styles.clouds} />
        </div>
    </section>
);

export default TraitsSection;
