import axios from 'axios';
import { ReportData, ServerResponse } from './types';
import { Question, Session, Answer } from '@/store/survey/types';
import { ROUTES } from './routes';
import camelcaseKeys from 'camelcase-keys';

class SurveyRepository {
    _config = {
        baseURL: process.env.REACT_APP_ASSESSMENT_API_URL,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ASSESSMENT_API_KEY}`
        }
    };

    async _get<T>(route: string) {
        const response = await axios.get<ServerResponse<T>>(route, this._config);

        return response.data;
    }

    async _put<T>(route: string, data: any) {
        const response = await axios.post<ServerResponse<T>>(route, data, this._config);

        return response.data;
    }

    async getQuestions() {
        const response = await this._get<Question[]>(ROUTES.QUESTIONS);

        return response.data;
    }

    async createSession() {
        const requestBody = {};
        const response = await this._put<Session>(ROUTES.SESSIONS, requestBody);

        return response.data;
    }

    async getReportLink(sessionId: string) {
        const requestBody = {};
        const response = await this._put<any>(
            `${ROUTES.SESSIONS}/${sessionId}/report`,
            requestBody
        );

        return response.data;
    }

    async sendAnswer(sessionId: string, answer: Answer) {
        const requestBody = {
            session_id: sessionId,
            ...answer
        };

        const response = await this._put<any>(ROUTES.ANSWERS, requestBody);

        return response.data;
    }

    submitEmailToMailingList(email: string, company?: string) {
        const requestBody = {
            source: 'pa',
            email,
            company
        };
        this._put<any>(`${process.env.REACT_APP_CORE_API_URL}${ROUTES.SUBMIT_EMAIL}`, requestBody);
    }

    async getUserReport(sessionId: string) {
        const response = await this._get<ReportData>(`${ROUTES.USER_REPORT(sessionId)}`);
        return camelcaseKeys(response, {
            deep: true
        });
    }

    getSession(sessionId: string) {
        return this._get<any>(`${ROUTES.SESSIONS_ANSWERS(sessionId)}`);
    }

    getSessionId(uniqueId: string) {
        return this._get<any>(`${ROUTES.GET_SESSIONS_ID(uniqueId)}`);
    }
}

export default new SurveyRepository();
