import React, { useEffect } from 'react';
import ConfettiGenerator from 'confetti-js';
import styles from './WhiteScreen.module.scss';
import classNames from 'classnames';

interface Props {
    confetti?: boolean;
    children?: any;
    title?: any;
    titleHTML?: any;
}

export default ({ confetti: confettiFlag, children, title, titleHTML }: Props) => {
    useEffect(() => {
        if (confettiFlag) {
            const confettiSettings = {
                target: 'confetti-canvas',
                max: 150,
                rotate: true,
                size: 1.8,
                animate: true,
                props: ['square'],
                clock: 40,
                colors: [
                    [129, 226, 224],
                    [91, 200, 211],
                    [96, 204, 213],
                    [87, 197, 210],
                    [83, 193, 209],
                    [111, 214, 218],
                    [129, 226, 224],
                    [157, 241, 233],
                    [159, 245, 234],
                    [151, 241, 231]
                ],
                respawn: false
            };
            const confetti = ConfettiGenerator(confettiSettings);
            confetti.render();
        }
    }, [confettiFlag]);

    return (
        <div className={styles['white-block']}>
            <canvas id="confetti-canvas" className={styles.confetti_canvas} />
            <div className={classNames('container', confettiFlag && styles.confetti_container)}>
                {titleHTML && (
                    <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: titleHTML }} />
                )}
                {title && <h2 className={styles.title}>{title}</h2>}
                {children}
            </div>
        </div>
    );
};
