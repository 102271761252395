import React, { PureComponent } from 'react';
import styles from './GamesAutocomplete.module.scss';
import { GameItem } from './GamesAutocomplete';
import Close from '@/svg/close.svg?component';

interface Props {
    item: GameItem;
    onDelete: Function;
}

class Tag extends PureComponent<Props> {
    onDelete = () => {
        const { item, onDelete } = this.props;

        onDelete(item.id);
    };

    render() {
        const { item } = this.props;

        return (
            <div className={styles.tag}>
                <div className={styles['tag--wrapper']}>
                    <div className={styles['tag--text']}>{item.name}</div>
                    <Close className={styles.close} onClick={this.onDelete} />
                </div>
            </div>
        );
    }
}

export default Tag;
