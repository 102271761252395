import { useState } from 'react';
import RevisitReportModal from '../RevisitReportModal';
import styles from './styles.module.scss';

const RevisitReportBanner = () => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    return (
        <div className={styles.banner__wrapper}>
            <div className={styles.banner__text}>
                Already taken the Wellbeing & Emotional Resilience Test?
                <span onClick={() => setIsOpened(true)} className={styles.banner__link}>
                    Revisit your Wellbeing & Emotional Resilience Report!
                </span>
            </div>
            {isOpened && <RevisitReportModal onClose={() => setIsOpened(false)} />}
        </div>
    );
};

export default RevisitReportBanner;
