import { Question } from './types';

export const getAnswerType = (type: string) => {
    switch (type) {
        case 'likert':
            return 'likert_value';
        case 'dropdown_single':
            return 'option_id';
        case 'single_text_input':
            return 'text_value';
        case 'dropdown_multiple':
        case 'multiple_text_inputs':
        case 'games_autocomplete':
        default:
            return 'values';
    }
};

const getAnswerValue = (question: Question) => {
    switch (question.type) {
        case 'likert':
            return null;
        case 'dropdown_multiple':
            return [];
        case 'dropdown_single':
            return null;
        case 'multiple_text_inputs':
            return question.options
                ? question.options.map(option => ({
                      id: option.id,
                      value: ''
                  }))
                : [];
        case 'single_text_input':
            return '';
        case 'games_autocomplete':
            return question.options
                ? question.options.map(option => ({
                      id: option.id,
                      value: ''
                  }))
                : [];
        default:
            return [];
    }
};

export const mapQuestionsToAnswers = (questions: Array<Question>): Array<any> =>
    questions.map(question => ({
        question_id: question.id,
        [getAnswerType(question.type)]: getAnswerValue(question)
    }));
